export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const SIGN_OUT = "/signout";
export const REGISTRATION_COMPLETE = "/complete";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/forgot-password";
export const RECOVERY = "/recovery";
export const FAQ = "/faq"

export const HOME = "/home";
export const COMPLAINTS = "/complaints";
export const DISEASES = "/diseases";
export const DISEASE_DETAIL = DISEASES + "/:disId";

export const SPECIALISATION_LIST = "/specialty/:slug";
export const SPECIALITY_DISEASE_DETAIL = SPECIALISATION_LIST + "/:disId";
export const DASHBOARD = "/dashboard/:dashId/:dashView";
export const DETAIL = DASHBOARD + "/detail/";
export const DETAIL_ID = DETAIL + ":disId";
