import React from "react";
import {
  LinealAmbulance,
  LinealBone,
  LinealBrain,
  LinealCrutches,
  LinealHospital,
  LinealHospitalBed,
  LinealMaternity,
  LinealMedicineBag,
  LinealPlaster,
  LinealScalpel,
  LinealStethoscope,
  LinealThroat
} from "components/shared/ui/icons/LinealSpecialisationIcons";

export const specialities = [
  {id:1,short_name:"ED",long_name:"Emergency Department",slug:"ed",icon:<LinealAmbulance />,pc:[109,2,10,99,12,13,28,30,33,34,35,36,43,45,44,58,59,63,62,78,79,93,94],dis:[38,41,80,131,142,168,174,283,295,502,648,685,779,780,802,838,854,998,873,946]},
{id:2,short_name:"Spec.Surg",long_name:"Specialty Surgery",slug:"ent-eye",icon:<LinealThroat />,pc:[15,20,21,23,24,27,28,29,37,40,46,56,61,64,72,74,76,77,81,82,93],dis:[18,31,84,85,94,129,154,160,187,190,270,271,327,345,392,449,482,486,494,517,601,603,612,625,643,658,702,711,752,753,755,760,766,791,818,875,900,904,905,909,928,936,950]},
{id:3,short_name:"GP",long_name:"General Practice (Primary Care / Integrated Care)",slug:"gp",icon:<LinealStethoscope />,pc:[3,5,9,101,14,16,19,22,24,32,33,46,49,51,52,53,59,64,70,71,81,87,90,92,95,96],dis:[15,35,43,73,75,83,84,148,149,179,180,213,245,254,308,334,367,424,431,452,471,519,533,549,551,598,610,628,654,666,682,699,718,782,783,808,847,852,859,870,872,886,897,932,952]},
{id:4,short_name:"Medicine",long_name:"General Medicine",slug:"medicine",icon:<LinealMedicineBag />,pc:[2,8,99,12,13,14,16,32,50,54,58,63,65,78,79,91,93,97],dis:[17,54,60,62,66,71,110,126,132,156,185,186,208,234,235,236,263,272,334,357,358,390,400,468,518,542,579,649,680,684,728,836,867,893,926,935]},
{id:5,short_name:"O&G",long_name:"Obstetrics and Gynaecology / Womens and Children Health",slug:"o-and-g",icon:<LinealMaternity />,pc:[3,18,35,36,66,67,69,83,84,85,92],dis:[5,79,137,140,250,251,264,265,298,384,616,617,618,638,678,687,698,777,835,841]},
{id:6,short_name:"Ortho",long_name:"Musculoskeletal",slug:"ortho",icon:<LinealBone />,pc:[138,4,7,137,11,142,26,139,34,140,38,141,43,47,144,48,143,55,59,60,135,62,145,80,148,86,146,88,147,98],dis:[1,10,11,86,89,120,128,159,167,173,222,225,237,244,312,440,464,483,488,503,548,608,611,631,632,713,727,775,783,804,848,918,961]},
{id:7,short_name:"Paeds",long_name:"Paediatrics",slug:"paeds",icon:<LinealPlaster />,pc:[1,2,10,11,12,101,14,16,24,25,31,32,33,39,44,45,52,54,60,65,70,78,79,81,82,94,97],dis:[14,18,20,1250,38,41,51,1251,1252,68,1253,1254,90,104,109,111,1255,1256,135,1257,178,1258,196,201,1259,204,234,252,254,262,271,314,1261,360,361,381,394,400,406,418,420,430,431,437,444,453,458,1262,1284,1263,1264,518,546,549,1267,1268,1283,608,661,1270,1271,738,743,782,798,802,1272,856,872,1273,879,1274,1275,905,1276,908,925,932,1277,1278,1279,1280,1281,969]},
{id:8,short_name:"Psych",long_name:"Psychiatry",slug:"psych",icon:<LinealBrain />,pc:[13,42,68],dis:[27,48,67,93,116,213,238,587,660,724,788,807,827]},
{id:9,short_name:"Surgery",long_name:"General Surgery",slug:"surg",icon:<LinealScalpel />,pc:[1,130,5,6,10,131,101,27,39,54,57,71,73,75,83,94],dis:[14,34,56,100,106,107,150,171,233,282,318,356,420,434,435,451,454,457,558,623,657,748,962]},
{id:10,short_name:"M.Specs",long_name:"Specialty Medicine",slug:"m-specs",icon:<LinealHospitalBed />,pc:[17,20,25,27,100,41,45,50,54,63,65,70,71,78,79,89,91,97],dis:[44,78,164,195,199,274,324,342,350,374,375,376,383,397,458,475,480,489,497,729,820,874,942,963]},
{id:11,short_name:"Geriatrics",long_name:"Geriatrics",slug:"geriatrics",icon:<LinealCrutches />,pc:[8,99,12,13,20,22,32,44,51,58,71,79,96],dis:[16,84,85,112,126,180, 208,213,311,358,475, 584, 610, 627,697, 935]},
{id:12,short_name:"Urgent Care",long_name:"Urgent Care",slug:"urgent-care",icon:<LinealHospital />,pc:[10,101,14,16,24,26,28,29,33,34,38,40,43,44,45,55,59,70,72,76,79,80,81,92,94],dis:[1,10,11,14,15,18,35,38,43,54,60,62,66,73,75,83,84,86,89,111,120,131,132,148,149,150,159,174,179,180,185,186,196,213,225,233,235,236,237,245,254,265,270,272,308,309,333,334,367,384,418,431,452,468,471,476,488,489,518,519,533,548,549,551,578,598,628,631,632,638,654,666,684,685,697,699,727,782,783,808,847,848,852,854,859,998,870,872,886,897,918,932,935,952,952,954,961]},
];
