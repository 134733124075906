import React, { useContext, useState } from "react";
import { History } from "history";
import AuthLayout from "components/shared/layouts/AuthLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";
import { AuthContext } from "components/context/AuthContext";
import { User } from "react-netlify-identity";
import * as ROUTES from "constants/routes";
import { Error } from "./Auth/types";

const SignInForm = React.lazy(() => import("./Auth/SignInForm"));

interface Props {
  history: History;
}
interface State {
  hasError: boolean;
  next: boolean | Function;
  error: Error;
}

const ViewSignIn = ({ history }: Props) => {
  const [state, setState] = useState<State>({
    hasError: false,
    next: false,
    error: null
  });
  const { loginUser } = useContext(AuthContext);

  const onSubmit = async (email: string, password: string) => {
    // const next = await authService.login(email, password)
    loginUser(email, password)
      .then((user: User) => {
        console.log("Success! Logged in", user);
        setState({...state, error: null, hasError:false });
        history.push(ROUTES.HOME);
      })
      .catch((error: Error) => {
        console.log(error);
        error && console.log(error.message);
        setState({...state, error: error, hasError:true });
      });


  };

  return (
    <AuthLayout>
      <Meta title={"Sign In"} />
      <React.Suspense fallback={<Loader absolute />}>
        <SignInForm error={ state.error? state.error.message:null } onSubmit={onSubmit} />
      </React.Suspense>
    </AuthLayout>
  );
};


export default ViewSignIn;
