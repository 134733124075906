import React from "react";
import styles from "./Logo.module.scss";

export const Logo = () => (
  <svg
    height="100px"
    width="100px"
    fill="#FFCF90"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    x="0px"
    y="0px"
  >
    <g data-name="line copy">
      <path d="M16,2A13.79,13.79,0,0,0,9.81,3.44a1,1,0,1,0,.88,1.79A12,12,0,0,1,28,16c0,4.88-2.62,10.32-7,11a3.26,3.26,0,0,1-2.71-.56A5,5,0,0,1,17,22.92,7,7,0,0,0,23,16V10a1,1,0,0,0-1-1H19a1,1,0,0,0,0,2h2v5a5,5,0,0,1-10,0V11h2a1,1,0,0,0,0-2H10a1,1,0,0,0-1,1v6a7,7,0,0,0,6,6.92c.13,2.3.78,4,1.94,5A4.67,4.67,0,0,0,20.18,29a8.55,8.55,0,0,0,1.1-.07C26.94,28.11,30,21.65,30,16A14,14,0,0,0,16,2Z" />
      <path d="M7,22a4.07,4.07,0,0,0-1.26.22A12,12,0,0,1,7.34,7.69,1,1,0,1,0,5.9,6.31a14,14,0,0,0-1.84,17A4,4,0,1,0,7,22Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,7,28Z" />
    </g>
  </svg>
);

export const LogoZ = () => (
  <svg
    width="102"
    height="100"
    viewBox="0 0 102 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.9928 99.9993C77.5907 99.9993 99.9632 77.6268 99.9632 50.029C99.9632 22.4311 77.5907 0.0585938 49.9928 0.0585938C22.395 0.0585938 0.0224609 22.4311 0.0224609 50.029C0.0224609 77.6268 22.395 99.9993 49.9928 99.9993Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M34.4309 21.4746H9.0174C4.77533 27.5418 1.93432 34.4757 0.699752 41.7751C-0.534819 49.0745 -0.132108 56.557 1.87877 63.6817H37.5719V24.5978C37.558 23.7707 37.2221 22.9817 36.6355 22.3985C36.0489 21.8152 35.258 21.4838 34.4309 21.4746V21.4746Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M5.35938 38.9283C8.51045 39.5363 11.7348 39.6686 14.9251 39.3209C18.4944 38.5892 20.2791 40.1062 22.6348 39.0711C23.5036 38.6801 24.4799 38.5978 25.4018 38.8381C26.3237 39.0783 27.1358 39.6265 27.7033 40.3917C28.3636 41.3733 30.1483 40.3917 31.4867 41.2662C32.964 41.8641 34.5525 42.138 36.1447 42.0693C36.1447 42.0693 25.9543 45.2995 5.35938 38.9283Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M6 31.6102C7.75202 32.0331 9.56691 32.1299 11.354 31.8958C13.3706 31.3961 14.2987 32.449 15.6907 31.7351C15.9303 31.6048 16.1941 31.5249 16.4658 31.5003C16.7375 31.4757 17.0114 31.5071 17.2705 31.5923C17.5297 31.6776 17.7686 31.815 17.9726 31.9961C18.1767 32.1772 18.3415 32.3981 18.4569 32.6453C18.7781 33.3235 19.8311 32.6453 20.5271 33.2521C21.3199 33.6601 22.2066 33.851 23.097 33.8054C23.097 33.8054 17.1719 35.9826 6 31.6102Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M37.2513 35.1649C37.2513 35.1649 30.5053 36.2714 27.3643 34.8972C28.3175 34.7558 29.2368 34.4412 30.0769 33.9692C30.5478 33.6568 31.1003 33.4902 31.6653 33.4902C32.2303 33.4902 32.7828 33.6568 33.2536 33.9692C33.9496 34.6473 34.2887 33.4516 35.0383 34.0941C35.7197 34.5571 36.4653 34.9179 37.2513 35.1649Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M15.3879 26.9734C13.9731 26.8867 12.5842 26.5545 11.2832 25.9918C11.6974 26.0532 12.1183 26.0532 12.5325 25.9918C12.7604 25.9228 13.0018 25.9111 13.2353 25.9578C13.4688 26.0045 13.6872 26.1081 13.871 26.2595C14.0851 26.5451 14.3707 26.2595 14.6205 26.4737C14.8528 26.6739 15.1109 26.8419 15.3879 26.9734V26.9734Z"
      fill="url(#paint5_linear)"
    />
    <path
      d="M29.5052 41.1074H23.5266C22.0932 41.1069 20.7163 41.6664 19.6895 42.6666C18.6627 43.6667 18.0671 45.0285 18.0299 46.4614V49.7273H12.0513C11.7626 49.7273 11.4857 49.842 11.2815 50.0462C11.0774 50.2503 10.9627 50.5272 10.9627 50.8159V56.1699H3.43142V54.1711C3.43144 54.0407 3.40548 53.9116 3.35504 53.7913C3.3046 53.671 3.23069 53.562 3.13765 53.4707C3.0446 53.3793 2.93428 53.3073 2.81312 53.2591C2.69197 53.2108 2.56241 53.1872 2.43201 53.1895H0.290428V44.6767C0.100586 46.4551 0.00527014 48.2423 0.00488281 50.0307C0.00673629 54.6292 0.643322 59.2054 1.89662 63.6298H37.5897V55.1884H29.5052V41.1074Z"
      fill="url(#paint6_linear)"
    />
    <path
      d="M12.0514 49.8164C11.9084 49.8164 11.7669 49.8446 11.6348 49.8993C11.5027 49.954 11.3827 50.0342 11.2816 50.1353C11.1805 50.2364 11.1003 50.3564 11.0456 50.4884C10.9909 50.6205 10.9627 50.7621 10.9627 50.9051V56.259H3.4315V56.5267C3.41964 57.1623 3.53459 57.7938 3.76964 58.3845C4.00468 58.9751 4.35511 59.5129 4.80043 59.9665C5.24574 60.4202 5.77702 60.7805 6.3632 61.0264C6.94938 61.2723 7.5787 61.3989 8.21437 61.3988H11.4624V59.3643H18.03V49.8164H12.0514Z"
      fill="url(#paint7_linear)"
    />
    <path
      d="M99.9638 50.0291C99.9913 39.8206 96.8758 29.851 91.0405 21.4746H43.4794C42.6369 21.4746 41.8289 21.8093 41.2332 22.405C40.6374 23.0008 40.3027 23.8088 40.3027 24.6513V63.6282H98.072C99.3253 59.2038 99.9619 54.6276 99.9638 50.0291V50.0291Z"
      fill="url(#paint8_linear)"
    />
    <path
      d="M95.1446 28.611C94.6092 27.4688 94.0025 26.3623 93.36 25.2559C93.0236 25.5196 92.7288 25.8325 92.4855 26.1839C90.8615 28.611 85.1684 32.1803 78.3867 34.5182C78.3867 34.5182 85.6503 32.448 95.1446 28.611Z"
      fill="url(#paint9_linear)"
    />
    <path
      d="M97.2511 33.8066C96.49 33.9665 95.7794 34.3096 95.1809 34.806C92.8494 36.4242 90.1372 37.4082 87.3105 37.6615C90.8812 37.2695 94.4266 36.6737 97.9292 35.8768C97.7151 35.1987 97.489 34.5086 97.2511 33.8066Z"
      fill="url(#paint10_linear)"
    />
    <path
      d="M70.5892 38.9283C67.4556 39.5309 64.2496 39.6632 61.077 39.3209C57.5077 38.5892 55.723 40.1062 53.3673 39.0711C52.4985 38.6801 51.5222 38.5978 50.6003 38.8381C49.6784 39.0783 48.8663 39.6265 48.2988 40.3917C47.6385 41.3733 45.8539 40.3917 44.5154 41.2662C43.0381 41.8641 41.4497 42.138 39.8574 42.0693C39.8574 42.0693 49.9943 45.2995 70.5892 38.9283Z"
      fill="url(#paint11_linear)"
    />
    <path
      d="M59.9876 33.0399C58.2357 33.4632 56.4207 33.56 54.6337 33.3255C52.617 32.8258 51.689 33.8787 50.2969 33.1648C50.0573 33.0345 49.7935 32.9546 49.5218 32.93C49.2501 32.9054 48.9762 32.9367 48.7171 33.022C48.458 33.1073 48.219 33.2447 48.015 33.4258C47.811 33.6069 47.6462 33.8278 47.5307 34.075C47.2095 34.7532 46.1565 34.075 45.4605 34.6818C44.6677 35.0898 43.7811 35.2807 42.8906 35.235C42.8906 35.235 48.8335 37.4123 59.9876 33.0399Z"
      fill="url(#paint12_linear)"
    />
    <path
      d="M57.3633 27.3991C57.3633 27.3991 64.1985 27.1671 67.0004 25.204C66.0396 25.2527 65.078 25.1257 64.1628 24.8292C63.6454 24.6047 63.0734 24.5375 62.518 24.6358C61.9626 24.7342 61.4485 24.9939 61.0397 25.3825C60.4864 26.1855 59.9332 25.0791 59.255 25.8643C58.702 26.4647 58.0648 26.9817 57.3633 27.3991V27.3991Z"
      fill="url(#paint13_linear)"
    />
    <path
      d="M79.4941 26.1669C77.6599 26.8118 75.7273 27.1318 73.7832 27.1127C74.3288 26.9395 74.8459 26.687 75.318 26.3632C75.5637 26.1623 75.8541 26.0235 76.1646 25.9582C76.4752 25.893 76.7969 25.9034 77.1027 25.9884C77.5131 26.2383 77.6738 25.6136 78.1556 25.8456C78.5835 26.0182 79.0345 26.1264 79.4941 26.1669V26.1669Z"
      fill="url(#paint14_linear)"
    />
    <path
      d="M81.6533 37.538C79.8191 38.1829 77.8865 38.5029 75.9424 38.4838C76.488 38.3106 77.0051 38.0581 77.4772 37.7343C77.7229 37.5334 78.0133 37.3945 78.3238 37.3293C78.6344 37.2641 78.9561 37.2745 79.2618 37.3595C79.6723 37.6094 79.8329 36.9847 80.3148 37.2167C80.7427 37.3893 81.1937 37.4975 81.6533 37.538V37.538Z"
      fill="url(#paint15_linear)"
    />
    <path
      d="M44.6758 24.705C46.0639 24.8719 47.4704 24.7994 48.834 24.4908C48.4168 24.4499 48.0079 24.3476 47.6205 24.1874C47.4094 24.0734 47.1733 24.0137 46.9334 24.0137C46.6935 24.0137 46.4573 24.0734 46.2463 24.1874C45.9786 24.4373 45.7823 24.0089 45.4789 24.1874C45.2367 24.3966 44.9663 24.5708 44.6758 24.705Z"
      fill="url(#paint16_linear)"
    />
    <path
      d="M99.9631 50.03C99.963 47.2736 99.7362 44.5219 99.285 41.8027C98.4911 41.8349 97.7401 42.1719 97.1883 42.7436C96.6365 43.3153 96.3264 44.0778 96.3224 44.8723V49.6195H94.1095V56.7582H77.2623L76.6377 63.6112H98.0536C99.3113 59.1934 99.9538 54.6233 99.9631 50.03V50.03Z"
      fill="url(#paint17_linear)"
    />
    <path
      d="M98.9108 60.2369H91.8971V41.2125C91.8971 40.9616 91.7975 40.7211 91.6201 40.5437C91.4427 40.3663 91.2021 40.2666 90.9513 40.2666H78.5836C78.3327 40.2666 78.0922 40.3663 77.9148 40.5437C77.7374 40.7211 77.6377 40.9616 77.6377 41.2125V53.1875H75.4961C75.2358 53.1875 74.9862 53.2909 74.8021 53.475C74.618 53.6591 74.5146 53.9088 74.5146 54.1691V56.2215H66.9476V50.8675C66.9476 50.5788 66.833 50.3019 66.6288 50.0977C66.4246 49.8935 66.1477 49.7788 65.859 49.7788H59.8983V37.8216C59.8983 34.8019 58.6987 31.9058 56.5633 29.7705C54.428 27.6351 51.5319 26.4355 48.5122 26.4355H48.4229V55.1863H40.3027V63.6278H98.072C98.3754 62.5213 98.6788 61.3791 98.9108 60.2369Z"
      fill="url(#paint18_linear)"
    />
    <path
      d="M88.2741 43.0176H89.3092C89.3585 43.0176 89.3984 42.9776 89.3984 42.9283V41.8932C89.3984 41.844 89.3585 41.804 89.3092 41.804H88.2741C88.2248 41.804 88.1849 41.844 88.1849 41.8932V42.9283C88.1849 42.9776 88.2248 43.0176 88.2741 43.0176Z"
      fill="url(#paint19_linear)"
    />
    <path
      d="M86.1862 43.0176H87.2213C87.2706 43.0176 87.3105 42.9776 87.3105 42.9283V41.8932C87.3105 41.844 87.2706 41.804 87.2213 41.804H86.1862C86.1369 41.804 86.097 41.844 86.097 41.8932V42.9283C86.097 42.9776 86.1369 43.0176 86.1862 43.0176Z"
      fill="url(#paint20_linear)"
    />
    <path
      d="M84.0807 43.0176H85.1158C85.1651 43.0176 85.2051 42.9776 85.2051 42.9283V41.8932C85.2051 41.844 85.1651 41.804 85.1158 41.804H84.0807C84.0315 41.804 83.9915 41.844 83.9915 41.8932V42.9283C83.9915 42.9776 84.0315 43.0176 84.0807 43.0176Z"
      fill="url(#paint21_linear)"
    />
    <path
      d="M82.0104 43.0176H83.0455C83.0948 43.0176 83.1348 42.9776 83.1348 42.9283V41.8932C83.1348 41.844 83.0948 41.804 83.0455 41.804H82.0104C81.9611 41.804 81.9212 41.844 81.9212 41.8932V42.9283C81.9212 42.9776 81.9611 43.0176 82.0104 43.0176Z"
      fill="url(#paint22_linear)"
    />
    <path
      d="M79.903 43.0176H80.9381C80.9874 43.0176 81.0273 42.9776 81.0273 42.9283V41.8932C81.0273 41.844 80.9874 41.804 80.9381 41.804H79.903C79.8537 41.804 79.8138 41.844 79.8138 41.8932V42.9283C79.8138 42.9776 79.8537 43.0176 79.903 43.0176Z"
      fill="url(#paint23_linear)"
    />
    <path
      d="M88.2741 45.2109H89.3092C89.3585 45.2109 89.3984 45.171 89.3984 45.1217V44.0866C89.3984 44.0373 89.3585 43.9974 89.3092 43.9974H88.2741C88.2248 43.9974 88.1849 44.0373 88.1849 44.0866V45.1217C88.1849 45.171 88.2248 45.2109 88.2741 45.2109Z"
      fill="url(#paint24_linear)"
    />
    <path
      d="M86.1862 45.2109H87.2213C87.2706 45.2109 87.3105 45.171 87.3105 45.1217V44.0866C87.3105 44.0373 87.2706 43.9974 87.2213 43.9974H86.1862C86.1369 43.9974 86.097 44.0373 86.097 44.0866V45.1217C86.097 45.171 86.1369 45.2109 86.1862 45.2109Z"
      fill="url(#paint25_linear)"
    />
    <path
      d="M84.0807 45.2109H85.1158C85.1651 45.2109 85.2051 45.171 85.2051 45.1217V44.0866C85.2051 44.0373 85.1651 43.9974 85.1158 43.9974H84.0807C84.0315 43.9974 83.9915 44.0373 83.9915 44.0866V45.1217C83.9915 45.171 84.0315 45.2109 84.0807 45.2109Z"
      fill="url(#paint26_linear)"
    />
    <path
      d="M82.0104 45.2109H83.0455C83.0948 45.2109 83.1348 45.171 83.1348 45.1217V44.0866C83.1348 44.0373 83.0948 43.9974 83.0455 43.9974H82.0104C81.9611 43.9974 81.9212 44.0373 81.9212 44.0866V45.1217C81.9212 45.171 81.9611 45.2109 82.0104 45.2109Z"
      fill="url(#paint27_linear)"
    />
    <path
      d="M79.903 45.2109H80.9381C80.9874 45.2109 81.0273 45.171 81.0273 45.1217V44.0866C81.0273 44.0373 80.9874 43.9974 80.9381 43.9974H79.903C79.8537 43.9974 79.8138 44.0373 79.8138 44.0866V45.1217C79.8138 45.171 79.8537 45.2109 79.903 45.2109Z"
      fill="url(#paint28_linear)"
    />
    <path
      d="M88.2741 47.4062H89.3092C89.3585 47.4062 89.3984 47.3663 89.3984 47.317V46.2819C89.3984 46.2326 89.3585 46.1927 89.3092 46.1927H88.2741C88.2248 46.1927 88.1849 46.2326 88.1849 46.2819V47.317C88.1849 47.3663 88.2248 47.4062 88.2741 47.4062Z"
      fill="url(#paint29_linear)"
    />
    <path
      d="M86.1862 47.4062H87.2213C87.2706 47.4062 87.3105 47.3663 87.3105 47.317V46.2819C87.3105 46.2326 87.2706 46.1927 87.2213 46.1927H86.1862C86.1369 46.1927 86.097 46.2326 86.097 46.2819V47.317C86.097 47.3663 86.1369 47.4062 86.1862 47.4062Z"
      fill="url(#paint30_linear)"
    />
    <path
      d="M84.0807 47.4062H85.1158C85.1651 47.4062 85.2051 47.3663 85.2051 47.317V46.2819C85.2051 46.2326 85.1651 46.1927 85.1158 46.1927H84.0807C84.0315 46.1927 83.9915 46.2326 83.9915 46.2819V47.317C83.9915 47.3663 84.0315 47.4062 84.0807 47.4062Z"
      fill="url(#paint31_linear)"
    />
    <path
      d="M82.0104 47.4062H83.0455C83.0948 47.4062 83.1348 47.3663 83.1348 47.317V46.2819C83.1348 46.2326 83.0948 46.1927 83.0455 46.1927H82.0104C81.9611 46.1927 81.9212 46.2326 81.9212 46.2819V47.317C81.9212 47.3663 81.9611 47.4062 82.0104 47.4062Z"
      fill="url(#paint32_linear)"
    />
    <path
      d="M79.903 47.4062H80.9381C80.9874 47.4062 81.0273 47.3663 81.0273 47.317V46.2819C81.0273 46.2326 80.9874 46.1927 80.9381 46.1927H79.903C79.8537 46.1927 79.8138 46.2326 79.8138 46.2819V47.317C79.8138 47.3663 79.8537 47.4062 79.903 47.4062Z"
      fill="url(#paint33_linear)"
    />
    <path
      d="M88.2741 49.6348H89.3092C89.3585 49.6348 89.3984 49.5948 89.3984 49.5455V48.5104C89.3984 48.4611 89.3585 48.4212 89.3092 48.4212H88.2741C88.2248 48.4212 88.1849 48.4611 88.1849 48.5104V49.5455C88.1849 49.5948 88.2248 49.6348 88.2741 49.6348Z"
      fill="url(#paint34_linear)"
    />
    <path
      d="M86.1862 49.6348H87.2213C87.2706 49.6348 87.3105 49.5948 87.3105 49.5455V48.5104C87.3105 48.4611 87.2706 48.4212 87.2213 48.4212H86.1862C86.1369 48.4212 86.097 48.4611 86.097 48.5104V49.5455C86.097 49.5948 86.1369 49.6348 86.1862 49.6348Z"
      fill="url(#paint35_linear)"
    />
    <path
      d="M84.0807 49.6348H85.1158C85.1651 49.6348 85.2051 49.5948 85.2051 49.5455V48.5104C85.2051 48.4611 85.1651 48.4212 85.1158 48.4212H84.0807C84.0315 48.4212 83.9915 48.4611 83.9915 48.5104V49.5455C83.9915 49.5948 84.0315 49.6348 84.0807 49.6348Z"
      fill="url(#paint36_linear)"
    />
    <path
      d="M82.0104 49.6348H83.0455C83.0948 49.6348 83.1348 49.5948 83.1348 49.5455V48.5104C83.1348 48.4611 83.0948 48.4212 83.0455 48.4212H82.0104C81.9611 48.4212 81.9212 48.4611 81.9212 48.5104V49.5455C81.9212 49.5948 81.9611 49.6348 82.0104 49.6348Z"
      fill="url(#paint37_linear)"
    />
    <path
      d="M79.903 49.6348H80.9381C80.9874 49.6348 81.0273 49.5948 81.0273 49.5455V48.5104C81.0273 48.4611 80.9874 48.4212 80.9381 48.4212H79.903C79.8537 48.4212 79.8138 48.4611 79.8138 48.5104V49.5455C79.8138 49.5948 79.8537 49.6348 79.903 49.6348Z"
      fill="url(#paint38_linear)"
    />
    <path
      d="M88.2741 51.832H89.3092C89.3585 51.832 89.3984 51.7921 89.3984 51.7428V50.7077C89.3984 50.6584 89.3585 50.6185 89.3092 50.6185H88.2741C88.2248 50.6185 88.1849 50.6584 88.1849 50.7077V51.7428C88.1849 51.7921 88.2248 51.832 88.2741 51.832Z"
      fill="url(#paint39_linear)"
    />
    <path
      d="M86.1862 51.832H87.2213C87.2706 51.832 87.3105 51.7921 87.3105 51.7428V50.7077C87.3105 50.6584 87.2706 50.6185 87.2213 50.6185H86.1862C86.1369 50.6185 86.097 50.6584 86.097 50.7077V51.7428C86.097 51.7921 86.1369 51.832 86.1862 51.832Z"
      fill="url(#paint40_linear)"
    />
    <path
      d="M84.0807 51.832H85.1158C85.1651 51.832 85.2051 51.7921 85.2051 51.7428V50.7077C85.2051 50.6584 85.1651 50.6185 85.1158 50.6185H84.0807C84.0315 50.6185 83.9915 50.6584 83.9915 50.7077V51.7428C83.9915 51.7921 84.0315 51.832 84.0807 51.832Z"
      fill="url(#paint41_linear)"
    />
    <path
      d="M82.0104 51.832H83.0455C83.0948 51.832 83.1348 51.7921 83.1348 51.7428V50.7077C83.1348 50.6584 83.0948 50.6185 83.0455 50.6185H82.0104C81.9611 50.6185 81.9212 50.6584 81.9212 50.7077V51.7428C81.9212 51.7921 81.9611 51.832 82.0104 51.832Z"
      fill="url(#paint42_linear)"
    />
    <path
      d="M79.903 51.832H80.9381C80.9874 51.832 81.0273 51.7921 81.0273 51.7428V50.7077C81.0273 50.6584 80.9874 50.6185 80.9381 50.6185H79.903C79.8537 50.6185 79.8138 50.6584 79.8138 50.7077V51.7428C79.8138 51.7921 79.8537 51.832 79.903 51.832Z"
      fill="url(#paint43_linear)"
    />
    <path
      d="M88.2741 54.0273H89.3092C89.3585 54.0273 89.3984 53.9874 89.3984 53.9381V52.903C89.3984 52.8537 89.3585 52.8138 89.3092 52.8138H88.2741C88.2248 52.8138 88.1849 52.8537 88.1849 52.903V53.9381C88.1849 53.9874 88.2248 54.0273 88.2741 54.0273Z"
      fill="url(#paint44_linear)"
    />
    <path
      d="M86.1862 54.0273H87.2213C87.2706 54.0273 87.3105 53.9874 87.3105 53.9381V52.903C87.3105 52.8537 87.2706 52.8138 87.2213 52.8138H86.1862C86.1369 52.8138 86.097 52.8537 86.097 52.903V53.9381C86.097 53.9874 86.1369 54.0273 86.1862 54.0273Z"
      fill="url(#paint45_linear)"
    />
    <path
      d="M84.0807 54.0273H85.1158C85.1651 54.0273 85.2051 53.9874 85.2051 53.9381V52.903C85.2051 52.8537 85.1651 52.8138 85.1158 52.8138H84.0807C84.0315 52.8138 83.9915 52.8537 83.9915 52.903V53.9381C83.9915 53.9874 84.0315 54.0273 84.0807 54.0273Z"
      fill="url(#paint46_linear)"
    />
    <path
      d="M82.0104 54.0273H83.0455C83.0948 54.0273 83.1348 53.9874 83.1348 53.9381V52.903C83.1348 52.8537 83.0948 52.8138 83.0455 52.8138H82.0104C81.9611 52.8138 81.9212 52.8537 81.9212 52.903V53.9381C81.9212 53.9874 81.9611 54.0273 82.0104 54.0273Z"
      fill="url(#paint47_linear)"
    />
    <path
      d="M79.903 54.0273H80.9381C80.9874 54.0273 81.0273 53.9874 81.0273 53.9381V52.903C81.0273 52.8537 80.9874 52.8138 80.9381 52.8138H79.903C79.8537 52.8138 79.8138 52.8537 79.8138 52.903V53.9381C79.8138 53.9874 79.8537 54.0273 79.903 54.0273Z"
      fill="url(#paint48_linear)"
    />
    <path
      d="M88.2741 56.2578H89.3092C89.3585 56.2578 89.3984 56.2179 89.3984 56.1686V55.1335C89.3984 55.0842 89.3585 55.0442 89.3092 55.0442H88.2741C88.2248 55.0442 88.1849 55.0842 88.1849 55.1335V56.1686C88.1849 56.2179 88.2248 56.2578 88.2741 56.2578Z"
      fill="url(#paint49_linear)"
    />
    <path
      d="M86.1862 56.2578H87.2213C87.2706 56.2578 87.3105 56.2179 87.3105 56.1686V55.1335C87.3105 55.0842 87.2706 55.0442 87.2213 55.0442H86.1862C86.1369 55.0442 86.097 55.0842 86.097 55.1335V56.1686C86.097 56.2179 86.1369 56.2578 86.1862 56.2578Z"
      fill="url(#paint50_linear)"
    />
    <path
      d="M84.0807 56.2578H85.1158C85.1651 56.2578 85.2051 56.2179 85.2051 56.1686V55.1335C85.2051 55.0842 85.1651 55.0442 85.1158 55.0442H84.0807C84.0315 55.0442 83.9915 55.0842 83.9915 55.1335V56.1686C83.9915 56.2179 84.0315 56.2578 84.0807 56.2578Z"
      fill="url(#paint51_linear)"
    />
    <path
      d="M82.0104 56.2578H83.0455C83.0948 56.2578 83.1348 56.2179 83.1348 56.1686V55.1335C83.1348 55.0842 83.0948 55.0442 83.0455 55.0442H82.0104C81.9611 55.0442 81.9212 55.0842 81.9212 55.1335V56.1686C81.9212 56.2179 81.9611 56.2578 82.0104 56.2578Z"
      fill="url(#paint52_linear)"
    />
    <path
      d="M79.903 56.2578H80.9381C80.9874 56.2578 81.0273 56.2179 81.0273 56.1686V55.1335C81.0273 55.0842 80.9874 55.0442 80.9381 55.0442H79.903C79.8537 55.0442 79.8138 55.0842 79.8138 55.1335V56.1686C79.8138 56.2179 79.8537 56.2578 79.903 56.2578Z"
      fill="url(#paint53_linear)"
    />
    <path
      d="M88.2741 58.4551H89.3092C89.3585 58.4551 89.3984 58.4151 89.3984 58.3658V57.3307C89.3984 57.2815 89.3585 57.2415 89.3092 57.2415H88.2741C88.2248 57.2415 88.1849 57.2815 88.1849 57.3307V58.3658C88.1849 58.4151 88.2248 58.4551 88.2741 58.4551Z"
      fill="url(#paint54_linear)"
    />
    <path
      d="M86.1862 58.4551H87.2213C87.2706 58.4551 87.3105 58.4151 87.3105 58.3658V57.3307C87.3105 57.2815 87.2706 57.2415 87.2213 57.2415H86.1862C86.1369 57.2415 86.097 57.2815 86.097 57.3307V58.3658C86.097 58.4151 86.1369 58.4551 86.1862 58.4551Z"
      fill="url(#paint55_linear)"
    />
    <path
      d="M84.0807 58.4551H85.1158C85.1651 58.4551 85.2051 58.4151 85.2051 58.3658V57.3307C85.2051 57.2815 85.1651 57.2415 85.1158 57.2415H84.0807C84.0315 57.2415 83.9915 57.2815 83.9915 57.3307V58.3658C83.9915 58.4151 84.0315 58.4551 84.0807 58.4551Z"
      fill="url(#paint56_linear)"
    />
    <path
      d="M82.0104 58.4551H83.0455C83.0948 58.4551 83.1348 58.4151 83.1348 58.3658V57.3307C83.1348 57.2815 83.0948 57.2415 83.0455 57.2415H82.0104C81.9611 57.2415 81.9212 57.2815 81.9212 57.3307V58.3658C81.9212 58.4151 81.9611 58.4551 82.0104 58.4551Z"
      fill="url(#paint57_linear)"
    />
    <path
      d="M79.903 58.4551H80.9381C80.9874 58.4551 81.0273 58.4151 81.0273 58.3658V57.3307C81.0273 57.2815 80.9874 57.2415 80.9381 57.2415H79.903C79.8537 57.2415 79.8138 57.2815 79.8138 57.3307V58.3658C79.8138 58.4151 79.8537 58.4551 79.903 58.4551Z"
      fill="url(#paint58_linear)"
    />
    <path
      d="M65.8582 49.8164C66.0012 49.8164 66.1427 49.8446 66.2748 49.8993C66.4069 49.954 66.5269 50.0342 66.628 50.1353C66.7291 50.2364 66.8093 50.3564 66.864 50.4884C66.9187 50.6205 66.9469 50.7621 66.9469 50.9051V56.259H74.4781V56.5267C74.49 57.1623 74.375 57.7938 74.14 58.3845C73.9049 58.9751 73.5545 59.5129 73.1092 59.9665C72.6638 60.4202 72.1326 60.7805 71.5464 61.0264C70.9602 61.2723 70.3309 61.3989 69.6952 61.3988H66.465V59.3643H59.8975V49.8164H65.8582Z"
      fill="url(#paint59_linear)"
    />
    <path
      className={styles.colourBurn}
      opacity="0.49"
      d="M101.195 43.2676C101.195 43.2676 101.195 43.3925 100.998 43.4818H100.873C100.791 43.4632 100.716 43.4196 100.659 43.3568C100.831 43.2969 101.012 43.2668 101.195 43.2676V43.2676Z"
      fill="url(#paint60_linear)"
    />
    <path
      d="M90.3091 9.37415C89.9981 9.23545 89.6572 9.17773 89.3179 9.20636C88.9786 9.235 88.6522 9.34906 88.3689 9.53792C88.0856 9.72677 87.8547 9.98428 87.6978 10.2864C87.5409 10.5886 87.463 10.9255 87.4715 11.2659C87.5201 13.508 87.182 15.7418 86.4721 17.8691C85.5174 20.86 84.1924 23.7198 82.528 26.3819C81.0208 28.808 80.3385 31.6564 80.5827 34.5021C81.0006 39.5241 81.7759 44.51 82.9028 49.4218C82.9891 49.7043 83.1369 49.9642 83.3356 50.1827C83.5344 50.4012 83.7791 50.5729 84.0521 50.6856C84.3252 50.7982 84.6198 50.8489 84.9148 50.8341C85.2098 50.8192 85.4978 50.7392 85.7582 50.5997C90.9337 47.6729 94.0747 41.6764 95.7344 37.4646C96.4918 35.6032 96.5987 33.5407 96.0378 31.611C95.6095 30.2011 95.4667 27.3456 96.4661 21.599C97.7868 14.1035 93.575 10.7662 90.3091 9.37415Z"
      fill="url(#paint61_linear)"
    />
    <path
      d="M95.734 37.4289C96.4914 35.5675 96.5983 33.5049 96.0374 31.5752C95.8968 31.0618 95.8071 30.5357 95.7697 30.0047C93.5664 24.9833 90.5517 20.3587 86.8464 16.3164C86.7215 16.8161 86.6144 17.298 86.4359 17.8512C85.4813 20.8421 84.1563 23.7019 82.4918 26.364C80.9847 28.7901 80.3023 31.6385 80.5466 34.4842C80.9645 39.5063 81.7398 44.4921 82.8666 49.4039C82.9529 49.6864 83.1008 49.9463 83.2995 50.1648C83.4982 50.3833 83.7429 50.555 84.016 50.6677C84.289 50.7803 84.5837 50.831 84.8787 50.8162C85.1737 50.8013 85.4617 50.7213 85.7221 50.5818C90.9333 47.6371 94.0743 41.605 95.734 37.4289Z"
      fill="url(#paint62_linear)"
    />
    <path
      d="M82.2772 48.7441C81.8386 47.3075 81.8386 45.7727 82.2772 44.336C83.0089 41.9446 87.185 40.267 86.9708 41.4271C86.7122 42.2785 86.2096 43.0355 85.5252 43.6043C85.5252 43.6043 88.9339 46.6026 86.2569 52.0279"
      fill="url(#paint63_linear)"
    />
    <path
      d="M41.6934 30.7734C41.6934 30.7734 50.5274 34.3428 61.3246 48.3166C70.5869 60.1846 79.4388 51.8859 81.8481 49.1197C82.0794 48.8544 82.3882 48.6686 82.7309 48.5885C83.0736 48.5085 83.4328 48.5382 83.7577 48.6735C84.4813 48.8885 85.1182 49.3273 85.5768 49.9269C86.0353 50.5266 86.2919 51.2562 86.3098 52.0108C86.3098 52.0108 75.9409 79.441 56.3454 65.949"
      fill="#40D3BA"
    />
    <path
      d="M73.5682 54.3473C69.9989 55.0076 65.7692 53.8833 61.4147 48.3151C52.4914 36.822 44.8531 32.3425 42.4795 31.1289L42.7472 33.2883L56.257 65.9297C57.6321 66.8892 59.1035 67.7026 60.6473 68.3568H60.8436L61.5218 68.6245C63.824 69.3205 66.6437 67.8928 64.7341 62.289C62.8246 56.6852 71.73 54.7399 73.5682 54.3473Z"
      fill="url(#paint64_linear)"
    />
    <path
      d="M34.3428 97.4843C42.616 100.203 51.4547 100.72 59.9883 98.9834C59.9883 96.2529 59.8277 93.6295 59.5957 91.3451C57.2578 68.3052 40.1786 89.4712 34.3428 97.4843Z"
      fill="#40D3BA"
    />
    <path
      d="M34.3428 97.4843C42.616 100.203 51.4547 100.72 59.9883 98.9834C59.9883 96.2529 59.8277 93.6295 59.5957 91.3451C57.2578 68.3052 40.1786 89.4712 34.3428 97.4843Z"
      fill="url(#paint65_linear)"
    />
    <path
      d="M25.6318 93.0922V93.6276C32.7534 97.6334 40.7527 99.8217 48.9216 99.9988C49.0722 97.1275 49.0722 94.2502 48.9216 91.3789L25.6318 93.0922Z"
      fill="url(#paint66_linear)"
    />
    <path
      d="M26.7919 29.3105C26.7919 29.3105 19.7604 30.7383 19.4034 40.429C19.0465 50.1196 22.8835 79.6914 21.5807 92.8265C21.5344 93.2609 21.6385 93.6981 21.8755 94.0651C22.1126 94.4321 22.4684 94.7067 22.8835 94.8431C28.7729 96.7527 50.6171 102.91 59.9508 92.8621C60.3125 92.4754 60.5107 91.9639 60.5041 91.4344C60.4148 84.849 59.1477 38.3588 41.7116 30.7561"
      fill="#40D3BA"
    />
    <path
      d="M27.2931 86.8315L34.5923 79.6928C34.5923 79.6928 41.1598 70.8766 32.3793 67.6821C23.5988 64.4876 29.9522 44.9277 29.9522 44.9277L19.6904 49.7642C20.5114 62.81 22.5637 82.7089 21.4751 92.8279C21.4288 93.2624 21.5328 93.6996 21.7699 94.0666C22.007 94.4336 22.3628 94.7082 22.7779 94.8446C23.9379 95.2193 25.6869 95.7547 27.8463 96.308C19.5477 93.6845 27.2931 86.8315 27.2931 86.8315Z"
      fill="url(#paint67_linear)"
    />
    <path
      d="M25.6318 29.7018C26.5777 30.2372 53.4903 51.6531 42.8181 31.772C42.5872 31.3316 42.2169 30.9802 41.7651 30.7726C39.3737 29.7197 31.7175 26.8285 25.6318 29.7018Z"
      fill="#DFECF4"
    />
    <path
      d="M47.8864 13.1952C47.0476 8.69783 43.9959 5.41406 39.3558 5.41406C36.5928 5.49812 33.9751 6.67079 32.073 8.67652C30.1709 10.6823 29.1386 13.3585 29.2011 16.122C29.3562 17.5798 29.8119 18.9897 30.5396 20.2624C31.218 22.2872 31.0771 24.4972 30.147 26.4195C29.7839 27.3414 29.1646 28.1402 28.3623 28.7217C38.4099 37.6449 44.2993 37.6449 42.1398 33.4867C41.1696 31.936 40.5936 30.1714 40.4623 28.3469C40.4232 27.9518 40.5254 27.5557 40.7509 27.2289C40.9764 26.9021 41.3104 26.6659 41.6937 26.5622C42.9022 26.1975 44.0121 25.563 44.9396 24.7066C45.8671 23.8503 46.5879 22.7944 47.0477 21.6187C47.2289 21.2008 47.551 20.8598 47.9578 20.655C48.2347 20.5234 48.4928 20.3553 48.7252 20.1553C49.564 19.4593 47.8864 13.1952 47.8864 13.1952Z"
      fill="url(#paint68_linear)"
    />
    <path
      d="M47.9036 19.3333C48.1356 17.7628 46.8685 14.1578 47.1897 14.2114C47.5021 14.1601 47.7967 14.0312 48.0463 13.8366L47.8857 13.1763C47.6581 11.9846 47.2488 10.835 46.6722 9.76758L30.6103 10.6599C30.1827 11.4205 29.8472 12.2293 29.6109 13.0692L30.8601 18.8336L30.2712 19.726L30.521 20.297C31.1995 22.3219 31.0586 24.5318 30.1284 26.4541C29.7653 27.3761 29.1461 28.1749 28.3438 28.7563C31.2436 31.4726 34.5416 33.7295 38.1237 35.4488C37.8917 35.3239 36.339 34.4672 38.1237 34.0211C39.9083 33.5749 37.1956 32.5041 35.9821 30.5588C35.0184 29.0062 39.712 27.2929 41.6216 26.6504C39.2123 27.2037 37.7132 25.3833 37.5704 20.9752C37.3206 16.121 43.3527 14.3363 45.6906 15.068C48.0285 15.7997 46.9042 22.0639 46.8863 22.0817L47.0826 21.6534C47.2638 21.2355 47.5859 20.8944 47.9928 20.6897C48.2697 20.558 48.5278 20.39 48.7602 20.19C48.7602 20.19 47.6716 20.9038 47.9036 19.3333Z"
      fill="url(#paint69_linear)"
    />
    <path
      d="M35.9479 17.9059C36.0979 17.9054 36.2432 17.8539 36.3599 17.7598C36.4767 17.6658 36.558 17.5347 36.5904 17.3884C36.7689 16.5317 37.2864 14.8898 38.6071 14.5686C40.3917 14.1403 55.1865 13.9083 52.5809 5.35977C52.3414 4.19395 51.7961 3.11281 51.0009 2.22722C50.2058 1.34164 49.1894 0.683465 48.056 0.320201C46.9226 -0.0430632 45.713 -0.0983476 44.5512 0.160016C43.3894 0.418381 42.3173 0.981096 41.4447 1.79046C41.1815 2.06516 40.8657 2.28413 40.5162 2.43436C40.1667 2.58459 39.7906 2.663 39.4101 2.66494C36.3941 2.66494 29.8622 3.39665 28.3631 10.0713C26.5785 18.0844 31.397 19.4586 30.933 23.3134C31.7173 21.3118 31.7991 19.1034 31.165 17.0493C30.2192 13.3372 35.1627 12.6412 35.2876 17.2277C35.2852 17.3159 35.3005 17.4037 35.3326 17.4859C35.3648 17.5681 35.413 17.643 35.4746 17.7062C35.5361 17.7695 35.6097 17.8197 35.691 17.854C35.7723 17.8883 35.8597 17.9059 35.9479 17.9059V17.9059Z"
      fill="url(#paint70_linear)"
    />
    <path
      d="M23.8487 30.8438C23.8487 30.8438 6.00218 41.9265 7.09082 63.735C8.17946 85.5435 29.2027 84.8118 29.2027 84.8118C31.045 84.0329 32.5767 82.6645 33.5573 80.9212C33.5573 80.9212 14.0331 72.6404 23.8487 57.8277C33.6644 43.0151 31.7369 34.3238 23.8487 30.8438Z"
      fill="#40D3BA"
    />
    <path
      d="M33.2705 79.0829C31.4886 81.9138 28.9721 84.2083 25.9891 85.7219C25.895 85.7695 25.8176 85.8447 25.7674 85.9374C25.7172 86.0302 25.6966 86.1361 25.7082 86.2409C25.7199 86.3457 25.7633 86.4445 25.8326 86.5239C25.9019 86.6034 25.9939 86.6598 26.0962 86.6856L26.8636 86.9176C26.9308 86.9482 27.0038 86.964 27.0777 86.964C27.1516 86.964 27.2246 86.9482 27.2919 86.9176C30.3235 85.2389 32.8454 82.7725 34.5911 79.779C34.6206 79.7159 34.6371 79.6475 34.6395 79.5779C34.6419 79.5084 34.6303 79.439 34.6053 79.374C34.5803 79.309 34.5425 79.2498 34.494 79.1998C34.4456 79.1497 34.3876 79.11 34.3234 79.0829L33.9486 78.9045C33.833 78.8477 33.7008 78.8345 33.5763 78.8672C33.4517 78.9 33.3431 78.9766 33.2705 79.0829V79.0829Z"
      fill="#5AEDD1"
    />
    <path
      d="M44.6213 52.0103C44.917 52.0103 45.1567 51.7705 45.1567 51.4749C45.1567 51.1792 44.917 50.9395 44.6213 50.9395C44.3256 50.9395 44.0859 51.1792 44.0859 51.4749C44.0859 51.7705 44.3256 52.0103 44.6213 52.0103Z"
      fill="#5AEDD1"
    />
    <path
      d="M46.2102 62.522C46.5059 62.522 46.7456 62.2823 46.7456 61.9866C46.7456 61.6909 46.5059 61.4512 46.2102 61.4512C45.9145 61.4512 45.6748 61.6909 45.6748 61.9866C45.6748 62.2823 45.9145 62.522 46.2102 62.522Z"
      fill="#5AEDD1"
    />
    <path
      d="M47.2991 74.5356C47.5948 74.5356 47.8345 74.2959 47.8345 74.0002C47.8345 73.7045 47.5948 73.4648 47.2991 73.4648C47.0034 73.4648 46.7637 73.7045 46.7637 74.0002C46.7637 74.2959 47.0034 74.5356 47.2991 74.5356Z"
      fill="#5AEDD1"
    />
    <path
      d="M47.8342 89.1489C48.1299 89.1489 48.3696 88.9092 48.3696 88.6135C48.3696 88.3178 48.1299 88.0781 47.8342 88.0781C47.5385 88.0781 47.2988 88.3178 47.2988 88.6135C47.2988 88.9092 47.5385 89.1489 47.8342 89.1489Z"
      fill="#5AEDD1"
    />
    <path
      d="M43.4795 67.5181C43.3404 67.3851 43.239 67.2175 43.1857 67.0326C43.1324 66.8476 43.1291 66.6518 43.1761 66.4651C43.8733 63.1844 43.7507 59.7824 42.8192 56.5603C40.6776 48.6721 34.8239 43.3538 29.7912 44.728C24.7585 46.1022 22.4027 53.6513 24.5443 61.5395C25.373 64.7918 26.9913 67.7894 29.2558 70.2665C29.3918 70.4049 29.4884 70.5771 29.5354 70.7654C29.5825 70.9537 29.5784 71.1511 29.5235 71.3372C29.4502 71.5885 29.4374 71.8536 29.4861 72.1108C29.5348 72.368 29.6437 72.61 29.8038 72.8171C29.9639 73.0242 30.1707 73.1905 30.4073 73.3024C30.644 73.4143 30.9037 73.4686 31.1654 73.461C31.5265 73.4436 31.8723 73.3101 32.1513 73.0803C32.4304 72.8505 32.6277 72.5367 32.714 72.1856C32.8002 71.8346 32.7708 71.4651 32.63 71.1321C32.4893 70.7991 32.2447 70.5206 31.9328 70.3378C29.095 67.9417 27.0945 64.7035 26.2219 61.0933C24.3302 54.1332 26.1327 47.5478 30.2374 46.4235C34.3421 45.2991 39.1607 49.9928 41.1416 57.0243C42.2412 60.5666 42.1849 64.3668 40.981 67.875C40.8131 68.1424 40.724 68.4516 40.724 68.7673C40.724 69.083 40.8131 69.3923 40.981 69.6597C41.1286 69.9155 41.3424 70.1267 41.5999 70.2712C41.8575 70.4157 42.1492 70.488 42.4444 70.4806C42.6968 70.4729 42.9443 70.4083 43.1682 70.2916C43.3922 70.1749 43.587 70.0092 43.7379 69.8067C43.8889 69.6043 43.9922 69.3704 44.0402 69.1224C44.0882 68.8744 44.0795 68.6189 44.0149 68.3747C43.9309 68.04 43.7436 67.7403 43.4795 67.5181V67.5181Z"
      fill="#DFECF4"
    />
    <path
      d="M28.9166 45.8348C28.8778 45.8339 28.8402 45.8216 28.8084 45.7994C28.7767 45.7771 28.7522 45.746 28.7381 45.7099C27.7698 43.5239 26.9471 41.2763 26.2753 38.9817C24.8654 34.0918 24.7583 30.5938 25.9719 28.595C26.3358 28.011 26.8437 27.5303 27.4469 27.199C28.05 26.8678 28.7282 26.6971 29.4163 26.7033C29.4707 26.7033 29.5229 26.7249 29.5614 26.7634C29.5999 26.8019 29.6215 26.8541 29.6215 26.9085C29.6215 26.963 29.5999 27.0152 29.5614 27.0536C29.5229 27.0921 29.4707 27.1138 29.4163 27.1138C28.7843 27.1081 28.1622 27.2703 27.6135 27.5839C27.0647 27.8975 26.6091 28.3511 26.2931 28.8984C23.5448 33.4493 29.0237 45.5136 29.0772 45.6385C29.0888 45.6629 29.0953 45.6895 29.0962 45.7166C29.097 45.7437 29.0923 45.7706 29.0822 45.7958C29.0722 45.8209 29.057 45.8437 29.0377 45.8627C29.0184 45.8817 28.9954 45.8965 28.9701 45.9062L28.9166 45.8348Z"
      fill="#DFECF4"
    />
    <path
      d="M53.6158 60.2034C53.473 60.2034 50.1893 59.3468 50.0465 44.5341C50.0465 36.9672 47.5658 33.3086 45.5313 31.5597C44.3031 30.455 42.7635 29.7568 41.1232 29.5609C41.0688 29.5609 41.0166 29.5392 40.9781 29.5008C40.9396 29.4623 40.918 29.4101 40.918 29.3556C40.918 29.3012 40.9396 29.249 40.9781 29.2105C41.0166 29.172 41.0688 29.1504 41.1232 29.1504C41.2124 29.1504 50.332 29.6679 50.4391 44.5163C50.5462 58.7935 53.6158 59.7929 53.6515 59.7929C53.7036 59.7929 53.7535 59.8136 53.7903 59.8504C53.8271 59.8873 53.8478 59.9372 53.8478 59.9892C53.8478 60.0413 53.8271 60.0912 53.7903 60.1281C53.7535 60.1649 53.7036 60.1856 53.6515 60.1856L53.6158 60.2034Z"
      fill="#DFECF4"
    />
    <path
      d="M53.3331 60.041C54.0841 59.6479 54.3742 58.7204 53.9811 57.9694C53.588 57.2184 52.6606 56.9283 51.9096 57.3214C51.1586 57.7145 50.8685 58.642 51.2616 59.393C51.6547 60.144 52.5822 60.4341 53.3331 60.041Z"
      fill="#DFECF4"
    />
    <path
      d="M53.3331 60.041C54.0841 59.6479 54.3742 58.7204 53.9811 57.9694C53.588 57.2184 52.6606 56.9283 51.9096 57.3214C51.1586 57.7145 50.8685 58.642 51.2616 59.393C51.6547 60.144 52.5822 60.4341 53.3331 60.041Z"
      fill="#DFECF4"
    />
    <path
      d="M55.1278 63.4689C56.6036 62.6964 57.1737 60.8739 56.4012 59.3981C55.6287 57.9223 53.8061 57.3522 52.3303 58.1247C50.8546 58.8972 50.2844 60.7198 51.0569 62.1956C51.8294 63.6713 53.652 64.2414 55.1278 63.4689Z"
      fill="#DFECF4"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="49.9928"
        y1="115.615"
        x2="49.9928"
        y2="-25.5691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="532.769"
        y1="1725.38"
        x2="532.769"
        y2="-771.175"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="474.836"
        y1="179.989"
        x2="474.836"
        y2="-8.37813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="207.232"
        y1="127.886"
        x2="207.232"
        y2="-0.629494"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="242.308"
        y1="104.243"
        x2="242.308"
        y2="9.9129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="56.8865"
        y1="61.1656"
        x2="56.8865"
        y2="14.1774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="532.958"
        y1="1076.91"
        x2="532.958"
        y2="521.509"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="144.314"
        y1="505.7"
        x2="144.314"
        y2="307.062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="2601.56"
        y1="1723.21"
        x2="2601.56"
        y2="-770.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="953.988"
        y1="353.236"
        x2="953.988"
        y2="-9.20013"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="676.967"
        y1="159.089"
        x2="676.967"
        y2="55.8564"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="1102.56"
        y1="207.945"
        x2="1102.56"
        y2="-23.9045"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="598.03"
        y1="148.389"
        x2="598.03"
        y2="-9.79282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="428.201"
        y1="138.119"
        x2="428.201"
        y2="-17.4056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="339.854"
        y1="79.1972"
        x2="339.854"
        y2="6.0371"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="348.923"
        y1="90.5685"
        x2="348.923"
        y2="17.4081"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1="168.8"
        y1="55.5052"
        x2="168.8"
        y2="12.364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1="1315.45"
        y1="891.026"
        x2="1315.45"
        y2="491.712"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="2539.35"
        y1="1825.42"
        x2="2539.35"
        y2="564.607"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1="2677.34"
        y1="92.2585"
        x2="2677.34"
        y2="58.1936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1="2678.02"
        y1="92.2585"
        x2="2678.02"
        y2="58.1936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="2678.68"
        y1="92.2585"
        x2="2678.68"
        y2="58.1936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1="2679.38"
        y1="92.2585"
        x2="2679.38"
        y2="58.1936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1="2680.04"
        y1="92.2585"
        x2="2680.04"
        y2="58.1936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="2677.34"
        y1="94.4695"
        x2="2677.34"
        y2="60.4048"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1="2678.02"
        y1="94.4695"
        x2="2678.02"
        y2="60.4048"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint26_linear"
        x1="2678.68"
        y1="94.4695"
        x2="2678.68"
        y2="60.4048"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint27_linear"
        x1="2679.38"
        y1="94.4695"
        x2="2679.38"
        y2="60.4048"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint28_linear"
        x1="2680.04"
        y1="94.4695"
        x2="2680.04"
        y2="60.4048"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint29_linear"
        x1="2677.34"
        y1="96.6828"
        x2="2677.34"
        y2="62.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint30_linear"
        x1="2678.02"
        y1="96.6828"
        x2="2678.02"
        y2="62.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint31_linear"
        x1="2678.68"
        y1="96.6828"
        x2="2678.68"
        y2="62.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint32_linear"
        x1="2679.38"
        y1="96.6828"
        x2="2679.38"
        y2="62.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint33_linear"
        x1="2680.04"
        y1="96.6828"
        x2="2680.04"
        y2="62.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint34_linear"
        x1="2677.34"
        y1="98.8757"
        x2="2677.34"
        y2="64.8108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint35_linear"
        x1="2678.02"
        y1="98.8757"
        x2="2678.02"
        y2="64.8108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint36_linear"
        x1="2678.68"
        y1="98.8757"
        x2="2678.68"
        y2="64.8108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint37_linear"
        x1="2679.38"
        y1="98.8757"
        x2="2679.38"
        y2="64.8108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint38_linear"
        x1="2680.04"
        y1="98.8757"
        x2="2680.04"
        y2="64.8108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint39_linear"
        x1="2677.34"
        y1="101.09"
        x2="2677.34"
        y2="67.0258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint40_linear"
        x1="2678.02"
        y1="101.09"
        x2="2678.02"
        y2="67.0258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint41_linear"
        x1="2678.68"
        y1="101.09"
        x2="2678.68"
        y2="67.0258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint42_linear"
        x1="2679.38"
        y1="101.09"
        x2="2679.38"
        y2="67.0258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint43_linear"
        x1="2680.04"
        y1="101.09"
        x2="2680.04"
        y2="67.0258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint44_linear"
        x1="2677.34"
        y1="103.304"
        x2="2677.34"
        y2="69.2391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint45_linear"
        x1="2678.02"
        y1="103.304"
        x2="2678.02"
        y2="69.2391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint46_linear"
        x1="2678.68"
        y1="103.304"
        x2="2678.68"
        y2="69.2391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint47_linear"
        x1="2679.38"
        y1="103.304"
        x2="2679.38"
        y2="69.2391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint48_linear"
        x1="2680.04"
        y1="103.304"
        x2="2680.04"
        y2="69.2391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint49_linear"
        x1="2677.34"
        y1="105.499"
        x2="2677.34"
        y2="71.4338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint50_linear"
        x1="2678.02"
        y1="105.499"
        x2="2678.02"
        y2="71.4338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint51_linear"
        x1="2678.68"
        y1="105.499"
        x2="2678.68"
        y2="71.4338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint52_linear"
        x1="2679.38"
        y1="105.499"
        x2="2679.38"
        y2="71.4338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint53_linear"
        x1="2680.04"
        y1="105.499"
        x2="2680.04"
        y2="71.4338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint54_linear"
        x1="2677.34"
        y1="107.714"
        x2="2677.34"
        y2="73.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint55_linear"
        x1="2678.02"
        y1="107.714"
        x2="2678.02"
        y2="73.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint56_linear"
        x1="2678.68"
        y1="107.714"
        x2="2678.68"
        y2="73.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint57_linear"
        x1="2679.38"
        y1="107.714"
        x2="2679.38"
        y2="73.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint58_linear"
        x1="2680.04"
        y1="107.714"
        x2="2680.04"
        y2="73.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint59_linear"
        x1="-60279.2"
        y1="505.7"
        x2="-60279.2"
        y2="307.062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint60_linear"
        x1="5854.84"
        y1="49.3459"
        x2="5854.84"
        y2="49.2774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAF1D8" />
        <stop offset="0.51" stopColor="#7DEFA4" />
        <stop offset="1" stopColor="#2FBCA4" />
      </linearGradient>
      <linearGradient
        id="paint61_linear"
        x1="88.6418"
        y1="42.4139"
        x2="88.6569"
        y2="-15.4327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.84" stopColor="#CDDFEC" />
      </linearGradient>
      <linearGradient
        id="paint62_linear"
        x1="965.95"
        y1="536.266"
        x2="1232.72"
        y2="163.793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.84" stopColor="#CDDFEC" />
      </linearGradient>
      <linearGradient
        id="paint63_linear"
        x1="96.8935"
        y1="36.2516"
        x2="69.6775"
        y2="61.2367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBC19E" />
        <stop offset="0.48" stopColor="#FFB699" />
        <stop offset="1" stopColor="#F47E6D" />
      </linearGradient>
      <linearGradient
        id="paint64_linear"
        x1="65.3409"
        y1="44.6566"
        x2="26.4533"
        y2="61.3074"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#40D3BA" />
        <stop offset="0.35" stopColor="#34C5AC" />
        <stop offset="1" stopColor="#15A189" />
      </linearGradient>
      <linearGradient
        id="paint65_linear"
        x1="881.927"
        y1="1212"
        x2="706.469"
        y2="913.888"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#40D3BA" />
        <stop offset="0.35" stopColor="#34C5AC" />
        <stop offset="1" stopColor="#15A189" />
      </linearGradient>
      <linearGradient
        id="paint66_linear"
        x1="633.4"
        y1="613.4"
        x2="620.974"
        y2="501.88"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#40D3BA" />
        <stop offset="0.35" stopColor="#34C5AC" />
        <stop offset="1" stopColor="#15A189" />
      </linearGradient>
      <linearGradient
        id="paint67_linear"
        x1="199.033"
        y1="2147.92"
        x2="986.794"
        y2="2220.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#40D3BA" />
        <stop offset="0.35" stopColor="#34C5AC" />
        <stop offset="1" stopColor="#15A189" />
      </linearGradient>
      <linearGradient
        id="paint68_linear"
        x1="707.883"
        y1="730.787"
        x2="342.474"
        y2="468.108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBC19E" />
        <stop offset="0.48" stopColor="#FFB699" />
        <stop offset="1" stopColor="#F47E6D" />
      </linearGradient>
      <linearGradient
        id="paint69_linear"
        x1="867.256"
        y1="765.565"
        x2="393.672"
        y2="429.414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBC19E" />
        <stop offset="0.48" stopColor="#FFB699" />
        <stop offset="1" stopColor="#F47E6D" />
      </linearGradient>
      <linearGradient
        id="paint70_linear"
        x1="60.4334"
        y1="-2.17148"
        x2="25.1864"
        y2="17.4597"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.04" stopColor="#B0462A" />
        <stop offset="0.51" stopColor="#70332A" />
        <stop offset="0.99" stopColor="#4D2C2A" />
      </linearGradient>
    </defs>
  </svg>
);
