import React from "react";
import styles from "./LandingBackground.module.scss";

const LandingBackgroud = React.memo(() => (
  <svg
    width="100%"
    height="676"
    viewBox="0 0 1500 676"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    preserveAspectRatio="none"
  >
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1416.84 0H0V675.013L1325.81 546.402C1352.37 547.062 1375.48 526.674 1377.59 499.723L1416.84 0Z"
      fill="url(#landing_background_192513_paint_linear)"
    />
    <path
      d="M1500 100.315V0H0V668.843L1422.34 464.297C1449.41 465.431 1472.86 444.766 1475 417.627L1500 100.315Z"
      fill="url(#landing_background_924123_paint_linear)"
    />
    <defs>
      <linearGradient
        id="landing_background_192513_paint_linear"
        x1="27.2013"
        y1="592.503"
        x2="1195.01"
        y2="504.326"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#908FD5" stopOpacity="0.18" />
        <stop offset="1" stopColor="#4B4E80" />
      </linearGradient>
      <linearGradient
        id="landing_background_924123_paint_linear"
        x1="0"
        y1="334.422"
        x2="1500"
        y2="334.422"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#495791" />
        <stop offset="1" stopColor="#303068" />
      </linearGradient>
    </defs>
  </svg>
));

// linear-gradient(to right, #495791, #303068)

export default LandingBackgroud;
