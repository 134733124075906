import { IDataRow } from "store/crossfilter/CrossfilterTypes";
import { processDiseases } from "utils/dataPreprocessing";

const validateCache = () => {
  const localVersion = localStorage.getItem("version");
  const remoteVersion = process.env.REACT_APP_VERSION;
  if (localVersion !== remoteVersion) {
    localStorage.clear();
    localStorage.setItem("version", remoteVersion || "");
  }
  return localVersion !== remoteVersion;
};

const fetchData = async (name: string, url: string) => {
  validateCache();
  const cachedData = localStorage.getItem(name);
  if (cachedData) {
    console.log(`using local ${name}`);
    return JSON.parse(cachedData);
  } else {
    console.log(`fetching remote ${name}`);
    let res = await fetch(url).then(response => response.json());

    try {
      //try and put data in local cache
      localStorage.setItem(name, JSON.stringify(res));
    } catch (error) {
      console.error(error);
    }
    return res;
  }
};

const fetchDiseases = async () => {
  const diseases = await fetchData("diseases", "/data/diseases.json");
  const processed: IDataRow[] = await processDiseases(diseases);
  return processed;
};

const fetchtooltip = async () => {
  return await fetchData("tooltip", "/data/tooltip.json");
};

const fetchPresentingComplaints = async () => {
  return await fetchData(
    "presenting-complaints",
    "/data/presenting-complaints.json"
  );
};

export { fetchDiseases, fetchtooltip, fetchPresentingComplaints };
