import React from "react";
import classNames from "classnames";
import Button from "components/shared/ui/button/Button";
import Container from "components/shared/layouts/Container";
import * as ROUTES from "constants/routes";
import styles from "./CallToAction.module.scss";


interface Props {
  reverse?: boolean;
  plain?: boolean;
  noBg?: boolean;
  secondary?: boolean;
  title?: string;
  subtitle?: string;
  button?: string;
  href?: string;
  externalHref?: string;
}

const CTA = ({
  reverse = false,
  plain = false,
               noBg = false,
  secondary = false,
  title = "Get started now",
  subtitle = "Sign up now to access the dashboard!",
  button = "Create an Account",
  href = ROUTES.SIGN_UP,
  externalHref
}: Props) => {
  const hr = externalHref ? undefined : href;

  return (
    <div
      className={classNames(
        styles.cta,
        reverse && styles.reverse,
        plain && styles.plain,
        secondary && styles.secondary,
        noBg && styles.noBg
      )}
    >
      <Container>
        <div>
          <h2 className={styles.title}>{title}</h2>
          <p className={classNames(styles.sub, styles.subCenter)}>{subtitle}</p>
          <Button href={hr} externalHref={externalHref} accent>
            {button}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default CTA;
