import React, { FC } from "react";
import cx from 'classnames'
import styles from "./icon.module.scss";

interface TIcon {
  color?: string;
  size?: string;
  className?: string;
}
const createIcon = (
  content: React.ReactNode,
  name: string,
  viewBox = "0 0 24 24" as string
) => {
  let Icon: FC<TIcon> = props => (
    <svg
      className={cx(styles.root, props.className)}
      viewBox={viewBox}
      fill="currentColor"
      style={{ fill: props.color }}
    >
      {content}
    </svg>
  );
  Icon.displayName = `${name}Icon`;

  return React.memo(Icon);
};

export default createIcon;
