import React, { useContext } from "react";
import { ModalContext } from "components/context/ModalContext";
import { useStoreState } from "store/state";
import Loader from "components/shared/ui/loader/Loader";

const Content = React.lazy(() => import("./DiseaseDetail/Content"));

interface Props {
  match: { params: { disId: string } };
}

const Detail = (props: Props) => {
  const {
    match: { params }
  } = props;
  const id = params.disId;
  const current_id = useStoreState(state => state.tooltip.current_id);

  let { state, dispatch } = useContext(ModalContext);
  if (!state.open && id && current_id !== +id) {
    dispatch({ type: "open" });
  }

  return (
    <React.Suspense fallback={<Loader absolute />}>
      {id && <Content id={id} />}
    </React.Suspense>
  );
};

export default Detail;
