import React from "react";
import classNames from "classnames";
import styles from "./Footer.module.scss";
import { SITE_LONG_NAME, SITE_NAME } from "constants/site";
import Container from "components/shared/layouts/Container";

const options = [
  [
    {
      text: "Created by ",
      url: "https://www.linkedin.com/in/matthew-wright-4a63b326/",
      link: "Dr Matt Wright"
    },
    {
      text: "Code/design by ",
      url: "https://www.linkedin.com/in/lindendalegandar/",
      link: "Linden Dale-Gandar"
    }
  ],
  // [{ text: "Created for ", url: "https://undraw.co", link: "unDraw" }],
  [{ url: "mailto:wrightmedicalnz@gmail.com", link: "Email" }]
];

interface Props {
  dark?: boolean;
}

interface ItemProps {
  text?: string;
  url: string;
  link: string;
}

const FooterItem = ({ text = "", url, link }: ItemProps) => (
  <div className={styles.row}>
    {text}
    <a href={url} className={styles.link}>
      {link}
    </a>
  </div>
);

const Footer = ({ dark = false }: Props) => (
  <div className={classNames(styles.footer, dark && styles.footerDark)}>
    <Container>
      <div className={styles.disclaimer}>
        The Diagnostic Dashboard (www.diagnosticdash.com) is for informational purposes only, and is not intended to replace medical team diagnosis or treatment The author is no way responsible for your use of the Diagnostic Dashboard, or any information that you obtain from it.
        <br/>
        You acknowledge that, when using the Diagnostic Dashboard, you do so at your own choice and in agreement with this disclaimer. Do not ignore or delay discussing patients with a more senior doctor because of information accessed through the Diagnostic Dashboard. By using the website you agree to the terms and conditions.
      </div>
      <div className={styles.inner}>
        <a href="/" className={styles.textLogo}>
          {SITE_NAME}
          <br />
          {SITE_LONG_NAME}
        </a>
        <div className={styles.data}>
          {options.map((col: ItemProps[], i) => (
            <div className={styles.dataItem} key={i}>
              {col.map((row: ItemProps) => (
                <FooterItem
                  text={row.text}
                  url={row.url}
                  link={row.link}
                  key={row.link}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </Container>
  </div>
);

export default Footer;
