import { Facet, IDataRow } from "store/crossfilter/CrossfilterTypes";

export const FACETS: Facet[] = [
  {
    key: "data",
    dimension: (row: IDataRow) => [
      row.i +
        "|" +
        row.a +
        "|" +
        row.g +
        "|" +
        row.c +
        "|" +
        row.m +
        "|" +
        row.s +
        "|" +
        row.v +
        "|" +
        row.n +
        "|" +
        row.y,
      {
        id: row.i,
        disease: row.d,
        system: row.s,
        sieve: row.v,
        anatomy: row.n,
        specific_anatomy: row.y,
        common: row.c,
        morbidity: row.m,
        ethnicity: row.e
      }
    ]
  },
  {
    key: "Age",
    dimension: (d: IDataRow) => d.a
  },
  {
    key: "Sex",
    type: "tags",
    dimension: (d: IDataRow) => d.g
  },
  {
    key: "Ethnicity",
    type: "tags",
    dimension: (d: IDataRow) => d.e
  },
  {
    key: "Morbidity",
    dimension: (d: IDataRow) => d.m
  },
  {
    key: "Commonness",
    dimension: (d: IDataRow) => d.c
  },
  {
    key: "Symptoms Onset",
    type: "tags",
    dimension: (d: IDataRow) => d.o
  },
  {
    key: "anatomy",
    dimension: (d: IDataRow) => d.n
  },
  {
    key: "system",
    dimension: (d: IDataRow) => d.s
  },
  {
    key: "specific_anatomy",
    dimension: (d: IDataRow) => d.y
  },
  {
    key: "pc",
    type: "tags",
    dimension: (d: IDataRow) => d.p
  }
];

export const defaultFilters: Record<string, string[]> = {
  pc: ["0"],
  Age: ["e@18-34"],
  Sex: ["Male"],
  Ethnicity: ["NZ European"]
};

export const sieve = [
  "V-Vascular",
  "I-Infection",
  "T-Trauma",
  "A-Autoimmune & Inflammatory",
  "M-Medications & Iatrogenic",
  "I-Idiopathic",
  "N-Neoplasm",
  "A-Allergy & Diet",
  "B-Behaviour",
  "C-Congenital",
  "D-Degenerative",
  "E-Endocrine & Pregnancy"
];
