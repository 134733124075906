import React, { ReactNode } from "react";

const ModalContext = React.createContext<any | null>({ open: false });

let initialState = {
  open: false,
  headerText: undefined
};

let reducer = (state: any, action: { type: string; value: string }) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "open":
      return { ...state, open: true };
    case "close":
      return { ...state, open: false };
    case "toggle":
      return { ...state, open: !state.open };
    case "header":
      return { ...state, headerText: action.value };
  }
};

function ModalContextProvider({ children }: { children: ReactNode }) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export { ModalContextProvider, ModalContext };
