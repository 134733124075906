import React from "react";
import createIcon from "./createIcon";

const AlertIcon = createIcon(
  <React.Fragment>
    <path fill="currentColor" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
  </React.Fragment>,
  "AlertIcon"
);

export default AlertIcon;
