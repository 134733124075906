import { IDataRow } from "store/crossfilter/CrossfilterTypes";

interface IRawDiseaseData {
  data: IDataRow[];
  values: {
    [key: string]: string[] | number[];
  };
}

const createDataRow = (
  row: IDataRow,
  indices: { [key: string]: string[] | number[] }
): IDataRow => {
  let obj = JSON.parse(JSON.stringify(row));
  Object.entries(row).map(([key, row_index]) => {
    if (!indices[key]) {
      return undefined;
    }
    const val =
      row_index instanceof Array
        ? row_index.map((e: number) => indices[key][e])
        : indices[key][row_index];
    obj[key] = val;
    return val;
  });

  return obj;
};

export const processDiseases = async (raw: IRawDiseaseData) => {
  const data = raw.data;
  const indices = raw.values;
  return data.map((d: IDataRow) => {
    return createDataRow(d, indices);
  });
};
