import { createStore } from "easy-peasy";
import { store as tooltip, TooltipModel } from "store/tooltipState";
import {
  store as complaints,
  ComplaintModel
} from "store/presentingComplaintState";
import { store as crossfilter, CrossfilterModel } from "store/crossfilterState";
import { store as user, UserModel } from "store/userState";

const store = createStore({
  user,
  tooltip,
  complaints,
  crossfilter
});

export interface storeModel {
  user: UserModel;
  tooltip: TooltipModel;
  complaints: ComplaintModel;
  crossfilter: CrossfilterModel;
}

// export the typed hooks
export const useStoreActions = store.useStoreActions;
export const useStoreDispatch = store.useStoreDispatch;
export const useStoreState = store.useStoreState;

export default store;
