import { Action, action, Thunk, thunk } from "easy-peasy";
import { fetchPresentingComplaints } from "api/fetch";

export interface IPresentingCcmplaintLink {
  text: string;
  url: string;
}

export interface IPresentingCcmplaint {
  id: number;
  slug: string;
  name: string;
  anatomy: boolean;
  specific_anatomy: boolean;
  sieve: boolean;
  system: boolean;
  synonyms: string;
  filter_defaults: Record<string, string[]>;
  red_flags: IPresentingCcmplaintLink[];
  ref: IPresentingCcmplaintLink[];
}

export interface ComplaintModel {
  bySlug: {
    [key: string]: IPresentingCcmplaint;
  };
  idSlugMatch: {
    [key: number]: string;
  };
  allSlugs: string[];
  current: IPresentingCcmplaint;
  current_slug: string | undefined;
  fetched: boolean;
  fetching: boolean;
  complaintSet: Action<ComplaintModel, string>;
  loadComplaints: Action<ComplaintModel, IPresentingCcmplaint[]>;
  fetchingComplaints: Action<ComplaintModel, boolean>;
  setComplaints: Thunk<ComplaintModel, string>;
  fetchComplaints: Thunk<ComplaintModel, void>;
}

const initialState = {
  bySlug: {},
  idSlugMatch: {},
  allSlugs: [],
  current: {
    id: 0,
    slug: "error",
    name: "Complaint Not Found",
    anatomy: false,
    specific_anatomy: false,
    sieve: false,
    system: false,
    synonyms: "",
    filter_defaults: {},
    red_flags: [],
    ref: []
  },
  current_slug: undefined,
  fetched: false,
  fetching: false
};

export const store: ComplaintModel = {
  ...initialState,

  complaintSet: action((state: any, payload) => {
    state.current = state.bySlug[payload];
    state.current_slug = payload;
  }),
  loadComplaints: action((state: any, payload) => {
    state.bySlug = payload.reduce(
      function(d, t) {
        d[t.slug] = t;
        return d;
      },
      {} as {
        [key: string]: IPresentingCcmplaint;
      }
    );
    state.allSlugs = payload.map(t => t.slug);
    state.idSlugMatch = payload.reduce(
      function(d, t) {
        d[t.id] = t.slug;
        return d;
      },
      {} as {
        [key: number]: string;
      }
    );
    state.fetched = true;
  }),
  fetchingComplaints: action((state: any, payload) => {
    state.fetching = payload;
  }),
  setComplaints: thunk(async (actions, payload, { getState }) => {
    if (!getState().fetched) {
      await actions.fetchComplaints();
    }
    if (getState().current_slug !== payload) {
      actions.complaintSet(payload);
    }
  }),
  fetchComplaints: thunk(async actions => {
    actions.fetchingComplaints(true);
    let res = await fetchPresentingComplaints();
    actions.loadComplaints(res);
    actions.fetchingComplaints(false);
  })
};
