import React, { useState } from "react";
import classNames from "classnames";
import styles from "./Navbar.module.scss";
import Container from "components/shared/layouts/Container";
import { Link } from "react-router-dom";
import { useScroll } from "components/hooks/useScroll";
import { Logo } from "components/shared/ui/logo/Logo";
import { SITE_NAME } from "constants/site";
import * as ROUTES from "constants/routes";

interface Props {
  items: TNavItem[];
  extended?: boolean;
  fixed?: boolean;
  mobile?: boolean;
  // scrollTop: number;
}

interface TNavItem {
  name: string;
  url: string;
}

const NavItem = ({ url, name }: TNavItem) => (
  <li>
    <Link to={url}>{name}</Link>
  </li>
);

const Navbar = ({
  items,
  extended = false,
  fixed = true,
  mobile = false
}: // scrollTop
Props) => {
  const [mobileExpand, toggleExpand] = useState(false);
  const scrollTop = useScroll();
  const solid = scrollTop > 100;

  return (
    <div
      className={classNames(
        styles.navbar,
        extended && !solid && styles.extended,
        mobileExpand ? styles.opened : styles.closed,
        !fixed && styles.navbarNoFixed
      )}
    >
      <div className={styles.bg} />
      <nav
        className={classNames(
          styles.mobile,
          mobileExpand ? styles.opened : styles.closed
        )}
        style={{ maxHeight: mobileExpand ? items.length * 56 : 0 }}
      >
        <ul>
          <NavItem name={"FAQ"} url={ROUTES.FAQ} />
          {items.map((item: TNavItem) => (
            <NavItem
              key={"mobile" + item.name}
              name={item.name}
              url={item.url}
            />
          ))}
        </ul>
      </nav>
      <Container>
        <div className={styles.inner}>
          <Link to={ROUTES.HOME} className={styles.logo}>
            <Logo />
            <div className={styles.text}>{SITE_NAME}</div>
          </Link>
          <nav className={classNames(styles.menu, mobile && styles.menuNoMob)}>
            <ul>
              <NavItem name={"FAQ"} url={ROUTES.FAQ} />
              {items.map((item: TNavItem) => (
                <NavItem key={item.name} name={item.name} url={item.url} />
              ))}
            </ul>
          </nav>
          <div
            className={styles.menuMob}
            onClick={() => toggleExpand(!mobileExpand)}
          >
            <button className={styles.toggle}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                  className=""
                />
              </svg>
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
