import React from "react";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import Meta from "components/shared/Meta";
import GAListener from "components/context/GoogleAnalytics";
import store from "store/state";
import Routes from "components/Routes";
import { AuthContextProvider } from "components/context/AuthContext";
import { ModalContextProvider } from "components/context/ModalContext";
import ErrorBoundary from "components/shared/ErrorBoundary";
import FullPageError from "./shared/ui/error-view/ErrorView";

const App = () => {
  return (
    <div className="App">
      <AuthContextProvider>
        <ModalContextProvider>
          <StoreProvider store={store}>
            <Meta />
            <ErrorBoundary errorView={FullPageError}>
              <BrowserRouter>
                <GAListener trackingId={"UA-144253068-1"}>
                  <Routes />
                </GAListener>
              </BrowserRouter>
            </ErrorBoundary>
          </StoreProvider>
        </ModalContextProvider>
      </AuthContextProvider>
    </div>
  );
};

export default App;
