import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "constants/routes";
import PrivateRoute from "components/shared/PrivateRoute";

import NotFound from "components/views/viewNotFound";
import SignIn from "components/views/viewSignIn";
import SignUpPage from "components/views/viewSignUp";
import Dash from "components/views/viewDash";
import ComplaintList from "components/views/viewComplaintList";
import SignOut from "components/views/Auth/SignOut";
import RegistrationComplete from "components/views/viewRegistrationComplete";
import ViewForgotPassword from "components/views/viewForgotPassword";
import ViewRecovery from "components/views/viewRecovery";
import ViewRoot from "components/views/viewRoot";
import SpecialisationList from "components/views/viewSpecialisationList";
import DiseaseList from "components/views/viewDiseaseList";
import DiseaseDetail from "components/views/viewDiseaseDetail";
import SpecialityDetailList from "components/views/viewSpecialtyDetailList";
import { ALLOWED_ROLES } from "constants/roles";
import viewFaq from "components/views/viewFaq";

const Routes: FC = () => (
  <Switch>
    <Route exact path={ROUTES.LANDING} component={ViewRoot} />
    <Route path={ROUTES.SIGN_IN} component={SignIn} />
    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route
      path={ROUTES.REGISTRATION_COMPLETE}
      component={RegistrationComplete}
    />
    <Route path={ROUTES.PASSWORD_FORGET} component={ViewForgotPassword} />
    <Route path={ROUTES.RECOVERY} component={ViewRecovery} />
    <PrivateRoute
      path={ROUTES.HOME}
      component={SpecialisationList}
      roles={ALLOWED_ROLES}
    />
    <PrivateRoute
      path={ROUTES.COMPLAINTS}
      component={ComplaintList}
      roles={ALLOWED_ROLES}
    />
    <PrivateRoute
      path={ROUTES.SPECIALISATION_LIST}
      component={SpecialityDetailList}
      roles={ALLOWED_ROLES}
    />
    <PrivateRoute
      path={ROUTES.SPECIALITY_DISEASE_DETAIL}
      component={DiseaseDetail}
      roles={ALLOWED_ROLES}
    />
    <PrivateRoute
      path={ROUTES.DISEASES}
      component={DiseaseList}
      roles={ALLOWED_ROLES}
    />
    <PrivateRoute
      path={ROUTES.DISEASE_DETAIL}
      component={DiseaseDetail}
      roles={ALLOWED_ROLES}
    />

    <PrivateRoute
      path={ROUTES.DASHBOARD}
      component={Dash}
      roles={ALLOWED_ROLES}
    />
    <Route path={ROUTES.FAQ} component={viewFaq} />
    <Route path={ROUTES.SIGN_OUT} component={SignOut} />

    <Route component={NotFound} />
  </Switch>
);

export default Routes;
