import React, { useContext } from "react";
import { AuthContext } from "components/context/AuthContext";
import * as ROUTES from "constants/routes";
import { History } from "history";

interface Props {
  history: History;
}

const SignOut = ({ history }: Props) => {
  const { logoutUser, user } = useContext(AuthContext);
  console.log("LOGGING OUT!!");
  if (user) {
    console.log("ACTUAL LOG OUT!!");
    logoutUser()
  }

  history.push(ROUTES.LANDING);
  return <></>;
};

export default SignOut;
