import React from "react";
import classnames from "classnames";
import styles from "./Button.module.scss";
import { Link } from "react-router-dom";
import { analyticsEvent } from "components/context/GoogleAnalytics";

export interface TButton {
  className?: string;
  href?: string;
  externalHref?: string;
  disabled?: boolean;
  type?: string;
  onClick?: (e: any) => void;
  primary?: boolean;
  accent?: boolean;
  inverse?: boolean;
  warning?: boolean;
  rounded?: boolean;
  target?: string;
  children?: any;
  gaLabel?: string;
  gaAction?: string;
}

const Button = (props: TButton) => {
  if (props.href) {
    return (
      <Link to={props.href || ""}>
        <ButtonBase {...props} />
      </Link>
    );
  }
  if (props.externalHref) {
    if (props.gaAction) {
      return (
        <a
          href={props.externalHref || ""}
          target={props.target || "_blank"}
          onClick={() =>
            analyticsEvent({
              category: "External Link",
              action: props.gaAction || "",
              label: props.gaLabel || props.externalHref || ""
            })
          }
        >
          <ButtonBase {...props} />
        </a>
      );
    }
    return (
      <a href={props.externalHref || ""} target={props.target || "_blank"}>
        <ButtonBase {...props} />
      </a>
    );
  }
  return <ButtonBase {...props} />;
};

const ButtonBase = ({
  className,
  onClick,
  disabled = false,
  type = "button",
  primary,
  accent,
  inverse,
  warning,
  rounded,
  children
}: TButton) => (
  <button
    className={classnames(
      styles.button,
      {
        [styles.primary]: primary,
        [styles.accent]: accent,
        [styles.inverse]: inverse,
        [styles.warn]: warning,
        [styles.rounded]: rounded
      },
      className
    )}
    disabled={disabled}
    onClick={onClick ? e => onClick(e) : () => {}}
    type={type}
  >
    {children}
  </button>
);

export default Button;
