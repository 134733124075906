import React, { useState } from "react";
import AppLayout from "components/shared/layouts/AppLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";
import { History } from "history";
import { IDisease } from "store/crossfilter/CrossfilterTypes";
import { useStoreActions, useStoreState } from "store/state";
import CTA from "components/shared/ui/call-to-action/CallToAction";

const Content = React.lazy(() => import("./Dash/Content"));

interface Props {
  match: { params: { dashId: string; dashView: keyof IDisease } };
  history: History;
}

const Dashboard = (props: Props) => {
  const {
    match: { params }
  } = props;
  const [loading, setLoadState] = useState(true);
  const slug = params.dashId;
  const view = params.dashView;

  const fetched = useStoreState(state => state.crossfilter.fetched);
  const fetching = useStoreState(state => state.tooltip.fetching);
  const current = useStoreState(state => state.crossfilter.current);
  const complaint = useStoreState(state => state.complaints.current);

  const fetchDiseaseData = useStoreActions(
    actions => actions.crossfilter.fetchDiseaseData
  );
  const resetDisesases = useStoreActions(
    actions => actions.crossfilter.resetFacets
  );
  const setComplaints = useStoreActions(
    state => state.complaints.setComplaints
  );

  const setLoading = (b: boolean) => {
    if (loading !== b) {
      setLoadState(b);
    }
  };

  if (!fetched && !fetching) {
    fetchDiseaseData();
    setLoading(true);
  } else if (complaint.slug !== slug) {
    setComplaints(slug);
  } else if (current !== complaint.slug) {
    resetDisesases(complaint);
  } else {
    setLoading(false);
  }

  return (
    <AppLayout>
      <Meta title={"Dashboard"} />
      {!loading && !fetching ? (
        <React.Suspense fallback={<Loader absolute />}>
          <Content view={view} history={props.history} />
        </React.Suspense>
      ) : (
        <Loader />
      )}
      <CTA
        title={"We're keen for your feedback"}
        subtitle={"Drop us an email with any thoughts"}
        externalHref={`mailto:wrightmedicalnz@gmail.com?subject=DDX%20Feedback%20-%20${
          complaint.name
        }`}
        button={"Email Matt (wrightmedicalnz@gmail.com)"}
      />
    </AppLayout>
  );
};

export default Dashboard;
