import React from "react";
import * as ROUTES from "constants/routes";
import Button from "components/shared/ui/button/Button";
import AlertIcon from "components/shared/ui/icons/AlertIcon";
import styles from "./ErrorView.module.scss";

export const FullPageError = () => (
  <div className={styles.root}>
    <div className={styles.title}>Oops!</div>
    <div className={styles.subtitle}>
      It looks like you've encountered an error.
    </div>
    <div className={styles.text}>
      <b />
      <br />
      If you keep having trouble please contact: <br />
      <a href={"mailto:wrightmedicalnz@gmail.com"}>wrightmedicalnz@gmail.com</a>
      <br />
      <br />
      Otherwise, the link below will take you back to the home page
    </div>
    <Button externalHref={ROUTES.HOME} accent target="_self">
      Back to Home
    </Button>
  </div>
);

export const ComponentErrorView = () => (
  <div className={styles.component}>
    <div className={styles.icon}>
      <AlertIcon />
    </div>
    <div className={styles.text}>
      Hmmm.. something broke when loading this page element. <br />
      <span className={styles.subtext}>
        Try refreshing the page or{" "}
        <a href={"mailto:wrightmedicalnz@gmail.com"}>email us</a>
      </span>
    </div>
  </div>
);

export default FullPageError;
