import React, { useContext, useState } from "react";
import AuthLayout from "components/shared/layouts/AuthLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";
import { AuthContext } from "components/context/AuthContext";
import SendMessage from "components/shared/ui/illustrations/SendMessage";
import { Error } from "./Auth/types";

const ForgotPasswordForm = React.lazy(() =>
  import("components/views/Auth/ForgotPasswordForm")
);

const ViewForgotPassword = () => {
  const [error, setError] = useState<Error>(null);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const { requestPasswordRecovery } = useContext(AuthContext);

  const onSubmit = async (email: string) => {
    requestPasswordRecovery(email)
      .then(() => {
        setEmail(email);
        setError(null);
      })
      .catch((error: Error) => {
        setEmail(undefined);
        setError(error);
      });
  };

  return (
    <AuthLayout banner={SendMessage}>
      <Meta title={"Forgot Password"} />
      <React.Suspense fallback={<Loader absolute />}>
        <ForgotPasswordForm
          error={error ? error.message : undefined}
          email={email}
          onSubmit={onSubmit}
        />
      </React.Suspense>
    </AuthLayout>
  );
};

export default ViewForgotPassword;
