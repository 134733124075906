import React from "react";
import styles from "./AuthLayout.module.scss";
import { Logo } from "components/shared/ui/logo/Logo";
import { Link } from "react-router-dom";
import * as ROUTES from "constants/routes";
import Doctors from "components/shared/ui/illustrations/Doctors";

interface Props {
  banner?: React.ElementType;
  children: React.ReactNode;
}

const AuthLayout = ({ banner: Banner = Doctors, children }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.media}>
        <Link to={ROUTES.LANDING} className={styles.logo}>
          <Logo />
        </Link>
        <Banner />
      </div>
      <div className={styles.auth}>
        <div className={styles.formWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
