import React from "react";

export interface Props {
  errorView?: any;
}

interface State {
  error: null | Error;
  errorInfo: null | React.ErrorInfo;
}

class ErrorBoundary extends React.PureComponent<Props, State> {
  public state: State = {
    error: null,
    errorInfo: null
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });
  }

  public render() {
    const { errorView: ErrorView, children } = this.props;
    const { error } = this.state;

    return error ? <ErrorView /> || "Error Catch" : children;
  }
}

export const withErrorBoundary = (
  ComponentThatMayError: any,
  ErrorView: any
) => (props: any) => (
  <ErrorBoundary errorView={ErrorView}>
    <ComponentThatMayError {...props} />
  </ErrorBoundary>
);

export default ErrorBoundary;
