import React, { useContext } from "react";
import Navbar from "components/shared/ui/navbar/Navbar";
import Footer from "components/shared/ui/footer/Footer";
import * as ROUTES from "constants/routes";
import { AuthContext } from "components/context/AuthContext";
import "assets/scss/base.scss";
import styles from "./AppLayout.module.scss";
import { navItems } from "components/shared/layouts/AppLayout";

const signedOutNavItems = [
  { url: ROUTES.SIGN_IN, name: "Login" },
  { url: ROUTES.SIGN_UP, name: "Sign up" }
];

interface Props {
  children: any;
}

const SplashLayout = (props: Props) => {
  const identity = useContext(AuthContext);

  return (
    <div className={styles.root}>
      <Navbar items={identity.user ? navItems : signedOutNavItems} extended />
      <div>{props.children}</div>
      <Footer dark />
    </div>
  );
};

export default SplashLayout;
