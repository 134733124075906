import React from "react";
import AppLayout from "components/shared/layouts/AppLayout";
import Meta from "components/shared/Meta";
import styles from "./Faq.module.scss";
import Container from "components/shared/layouts/Container";

const viewFaq = () => (
  <AppLayout>
    <Meta title={"FAQ"} />
    <Container className={styles.content}>
      <h1>Frequently Asked Questions</h1>
      <h2>Why can’t I get onto the website?</h2>
      <p>
        Many hospital computers run Internet Explorer as their default browser,
        which is not compatible with the website
        <br />
        On the hospital computers use: Mozilla Firefox. Google Chrome and Safari
        will also work fine.
        <br />
        <br />
        If you have further issues please email: &nbsp;
        <a href="mailto:wrightmedical@gmail.com" target="_blank" rel="noopener noreferrer">
          wrightmedical@gmail.com
        </a>
        <br />
      </p>
      <h2>Would you recommend to use it on a computer or a smartphone?</h2>
      <p>
        The website was originally written to be used on a computer, with the
        tabular format to more easily navigate, to maximize learning.
        <br />
        More white space also makes it easier to read. So ideally it is used on
        a computer (e.g in the ED or wards at the hospital, in a primary care clinic, computer room at the 
        university, or at home.
        <br />
        However, feedback from students, nurses and doctors is that any point of care diagnostic resource  
        needs to also be available on a smartphone, as this makes it always accessible for clinicians.
        Therefore exactly the same information is shown on the phone - but in a
        more suitable format.
        <br />
      </p>
      <h2>What has the review process been for the content?</h2>
      <p>
        The author has endeavoured to make the content as accurate as possible.
        <br />
        <br />
        The frameworks are the most well-known ones, and are mentioned in the
        general medicine teaching material at Auckland University and in the
        podcast IMReasoning, as well as being well discussed as best practice at
        the Diagnostic medicine conference through the same named society
        (SIDM). Each disease has been coded to each framework and this is one of
        the unique features of the website.
        <br />
        <br />
        The commonness brackets are based on a combination of a literature
        review; the authors own experience seeing 10,000 patients acutely over
        the last 15 years, and that of specialist colleagues in every field. It
        is acknowledged that depending on the setting these commonness brackets
        may change, but these are generally considered accurate, aiming to give
        the medical student and junior doctor a sense of pre-test probability
        for a patient presenting with a symptom to any setting. Therefore they
        would be more accurate for the commonness of presentation to a community
        clinic or emergency department. However the commonness brackets would
        not be entirely accurate for use in an outpatient setting, as these
        patients have already been screened by a doctor and the emergency
        diseases ruled out. It is advised to concentrate on the more chronic
        conditions and the disease descriptions when using the dashboard in an
        outpatient setting.
        <br />
        <br />
        The morbidity brackets are based on whether a disease can cause loss of
        life or limb, coupled with whether there has ever been a medico-legal
        case against a doctor for that disease. Any disease that fulfils any of
        these criteria always is considered high, and if it has the potential
        to, but usually doesn’t, then it is classed as medium morbidity.
        Examples of this are asthma exacerbations, which can cause death but it
        is very rare and cellulitis, which can warrant hospital admission and
        progress to septicaemia, but both can also be mild and treated at the
        local GP. The rest of the diseases (Around half) are classed as low
        morbidity, and rarely cause significant pathology, but can cause
        frustrating symptoms for a prolonged period of time.
        <br />
        <br />
        The short information on each disease is written with specialists in
        every field and then has been reviewed by medical students and junior
        doctors to get the level of content right for those groups. It aims to
        give a user a quick overview of the disease in a combination of bullet
        point and short text. It is only a reference guide, and is slightly more
        aimed at primary care, emergency care and the initial treatment of a
        disease. This is the feedback that students gave on what information was
        most helpful for their level of study.
        <br />
        <br />
      </p>
      <h2>Why did you decide on those references?</h2>
      <p>
        The author wanted to give open source, consistent and well renowned
        references with a combination of local, national and international
        flavour. Relevant university references are also included. Therefore
        Health Pathways, Dermnet, Starship guidelines and{" "}
        <a href="http://patient.co.uk/" target="_blank" rel="noopener noreferrer">
          patient.co.uk
        </a>{" "}
        were selected which fulfil this criteria. Wheeles online is the website
        used for orthopaedic topics.
        <br />
        <br />
      </p>
      <h2>What if I find a mistake in the content of the website?</h2>
      <p>
        We are very open to feedback. Please use the email at the bottom of each
        page. Please include relevant supporting information or reference, if
        applicable.
        <br />
        <br />
        In the unlikely event of a major error, we would look to make changes
        within 48 hours.
        <br />
        All other small changes will be made regularly, every 2 weeks on
        average.
        <br />
        We may contact you about the requested change once it's been discussed
        with the specialist team.
        <br />
        <br />
      </p>
      <h2>
        I'm from Otago university, can I access the Auckland university
        material?
      </h2>
      <p>
        You, and senior nurses and doctors, can only access the free open source material. The
        Auckland university material remains the property of the university and
        is password protected for their students.
        <br />
        <br />
      </p>
      <h2>
        Not all Health Pathways are relevant to the region I am working or
        studying in, why is this?
      </h2>
      <p>
        Auckland Health Pathways have been kind enough to allow the region's
        version to be available for medical students from the New Zealand
        universities to reference, to have the most up to date national
        information and be familiar with them before starting as a doctor. The
        majority of the material is relevant no matter where you work, but the
        phone numbers given and referral pathways are generally Auckland
        specific.
        <br />
        <br />
      </p>
      <h2>What is the website's goal?</h2>
      <p>
        To give medical students frameworks for differential diagnosis and
        encourage them to make a differential for every patient they see. 
        This is something which was missing from the authors' training, so this
        website aims to right this.
        <br />
        <br />
        To populate these frameworks for the majority of presenting complaints
        they will encounter
        <br />
        <br />
        To provide short "illness scripts" on all major diseases encountered
        with appropriate references.
        <br />
        <br />
        The overall goal is that NZ clinicians become more skilled at
        differential diagnosis, more knowledgeable on a broad range of diseases,
        can more accurately rule out high morbidity diseases and through all of
        this it translates through to better patient care. 
        <br />
        <br />
      </p>
      <h2>How is the website funded?</h2>
      <p>
        Currently the website is self-funded by the author, due to his interest
        in the topic and in medical education and the fact that he felt there is
        a gap in the market for a resource like this. In the future there may need to
        be advertising or a small subscription for earning clinicians, however
        it is hopd this will always be free for New Zealand medical students (years 2-5).
        <br />
        <br />
      </p>
      <h2>How would you recommend to use the site?</h2>
      <p>
        The initial landing page after sign in gives you the option of accessing
        information by specialty, presenting complaint or disease. Each
        specialty page suggests presenting complaints and diseases for that
        attachment / rotation.
        <br />
        <br />
        If trying to find a specific presenting complaint (for example to use
        with a patient) or disease (for example to study) then use the search
        function and type in the letters and it will give you the relevant
        information.
        <br />
        <br />
      </p>
      <h2>
        How do you decide which presenting complaint to choose if the patient
        has more than one?
      </h2>
      <p>
        If there is one main presenting complaint that is bothering them, then
        select that.
        <br />
        <br />
        However if they have 2-3 equally impacting symptoms, then apply the
        hierarchy rules. These rules state that more rare and specific
        presenting complaints are more likely to get you the correct diagnosis,
        as they tend to have shorter differential diagnoses. For example if a
        patient has abdominal pain and haematemesis equally, you should open the
        haematemesis complaint. However if they have abdominal pain and has
        vomited twice with a small trace of blood in the vomit, you should
        choose abdominal pain.
        <br />
        <br />
      </p>
      <h2>I can’t find the disease on the presenting complaint</h2>
      <p>
        It is vitally important to pick the most accurate presenting complaint,
        as the differential is driven by this. Therefore the most likely reason
        that you can’t find the disease is that there is a better presenting
        complaint.
        <br />
        <br />
        If you think that a disease should be included in the differential for a
        presenting complaint please email this as feedback. We have endeavoured
        to have accurate lists and cross referenced them against databases, but
        may have missed the occasional one. We would then amend the dashboard to
        reflect your suggestion.
      </p>
      <h2>
        Why did you decide on having some presenting complaints with a second
        symptom or finding (e.g fever, hypotension or CRP), and not others?
      </h2>
      <p>
        The website is set up to support a user to understand the diseases that
        cause any single presenting complaint, with references and red flags
        that align to that presenting complaint. Therefore most times it is
        advised to keep the differential diagnosis broad.
      </p>
      <p>
        However, it is recognised that certain key positive findings do shorten
        the differential diagnosis, and so therefore are included where
        relevant. One of the examples of this, is that the injury presenting
        complaints assume that the person has had a reasonable force to that
        body part, and that this has bought them to see the clinician and is
        causing the pain. The dashboard for this injury then excludes those more
        chronic, inflammatory or other non relevant diseases.
      </p>
      <p>
        For some presenting complaints, having a documented fever (&gt;37.9)
        does change the differential, as long as the temperature is related to
        that symptom. This will then only show the infective, inflammatory and
        neoplastic causes, shortening the differential. If the patient has a
        raised CRP (&gt;80), then this again shortens the differential, as viral
        causes and some rheumatological diseases are very unlikely to have a CRP
        at this level. A patient with a CRP &gt; 80 should always be
        investigated further if the cause initially is not obvious (e.g
        cellulitis), as they are much more likely to have a disease with
        significant morbidity.
      </p>
      <p>
        The purpose of giving a second symptom or finding is to shorten the
        differential, while still having an accurate list.
      </p>
      <p>
        What is not shown is when two symptoms almost always are due to a
        disease. For example most diarrhoea and vomiting is caused by
        gastroenteritis. While a lot of things which cause diarrhoea can cause a
        little vomiting. So having a diarrhoea and vomiting presenting complaint
        doesn’t really change the differential.
      </p>
      <h2>
        Where did you get the red flags from for each presenting complaint?
      </h2>
      <p>
        The red flags are directly from the website GPonline with review papers
        on all the common presenting complaints and the high morbidity things to
        watch for. It is a paid website, hence the links to the actual pages are
        not included.
        <br />
        <br />
        The red flags from Health Pathways are also included, where applicable.
        <br />
        <br />
        In general the red flags written are aimed for the primary care or
        emergency doctor to either be wary of when a patient presents with both
        symptoms (e.g. chest pain and shortness of breath) or a reason for
        investigation (headache which is sudden onset and severe usually
        warrants CT Head)
        <br />
        <br />
        <br />
        <br />
      </p>
    </Container>
  </AppLayout>
);

export default viewFaq;
