import React from "react";
import SplashLayout from "components/shared/layouts/SplashLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";
import { SITE_NAME } from "constants/site";

const Content = React.lazy(() => import("./Splash/Content"));

const ViewSplash = () => {
  return (
    <SplashLayout>
      <Meta title={SITE_NAME} />
      <React.Suspense fallback={<Loader absolute />}>
        <Content />
      </React.Suspense>
    </SplashLayout>
  );
};

export default ViewSplash;
