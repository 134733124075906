import React, { memo } from "react";
import styles from "./illustration.module.scss";
import { TSvg } from "./illustrationDefs";
import cx from "classnames";

const _Svg = (props: TSvg) => (
  <svg
    id="be452e83-5912-4855-80f8-a79ecab91838"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="915.11162"
    height="600.53015"
    viewBox="0 0 915.11162 600.53015"
    color={props.color}
    className={cx(props.className, props.alternative && styles.alternative)}
  >
    <title>happy_news</title>
    <path
      d="M331.91711,410.02944c-46.27673.2133-83.94416.897-83.94416.897s44.77365-59.30728,83.77624-37.33435,83.80662,36.562,83.80662,36.562S378.19384,409.81615,331.91711,410.02944Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#f2f2f2"
    />
    <circle cx="708.36214" cy="268.22391" r="9.69362" fill="#ff6584" />
    <rect
      x="762.01542"
      y="527.38147"
      width="110.37066"
      height="2.96651"
      transform="translate(-260.75252 648.08652) rotate(-48.9494)"
      fill="#3f3d56"
    />
    <circle cx="710.99864" cy="337.51277" r="15.60802" fill="#3f3d56" />
    <path
      d="M808.76979,538.54659s29.81274-37.62973-8.31873-63.44438"
      transform="translate(-142.44419 -149.73493)"
      fill="#3f3d56"
    />
    <path
      d="M322.2992,604.62239c53.6896,99.56973,167.63821,142.31286,167.63821,142.31286s26.90056-118.69128-26.789-218.261S295.51015,386.36139,295.51015,386.36139,268.60959,505.05267,322.2992,604.62239Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#3f3d56"
    />
    <path
      d="M359.2021,571.86466c96.93185,58.3174,134.26337,174.15185,134.26337,174.15185s-119.82606,21.28324-216.75791-37.03417S142.44419,534.83049,142.44419,534.83049,262.27025,513.54725,359.2021,571.86466Z"
      transform="translate(-142.44419 -149.73493)"
      className={styles.primary}
    />
    <path
      d="M744.87555,572.68892c-97.44388,57.45776-135.79849,172.95747-135.79849,172.95747s119.63312,22.34228,217.077-35.11549,135.7985-172.95747,135.7985-172.95747S842.31943,515.23115,744.87555,572.68892Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#3f3d56"
    />
    <path
      d="M779.02045,608.31221C724.45225,707.40315,610.13,749.13674,610.13,749.13674s-25.84968-118.92458,28.71852-218.01552S807.739,390.29669,807.739,390.29669,833.58865,509.22127,779.02045,608.31221Z"
      transform="translate(-142.44419 -149.73493)"
      className={styles.primary}
    />
    <path
      d="M580.991,335.58005a30.28312,30.28312,0,0,1-3.76211-40.8299c-.60975.57174-1.20581,1.16536-1.7764,1.79643a30.2867,30.2867,0,0,0,44.93083,40.62459c.57059-.63107,1.10136-1.28373,1.609-1.94781A30.28311,30.28311,0,0,1,580.991,335.58005Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#2f2e41"
    />
    <circle cx="411.11356" cy="144.96469" r="62.37618" fill="#2f2e41" />
    <path
      d="M536.42322,374.84628l1.84844-4.80595L504.63,398.50633,463.96432,421.427s-20.70254-79.85266-22.1813-81.33142-28.0963-57.67136-39.92633-31.05381,22.92068,42.14446,22.92068,42.14446,1.10906,97.228,36.59913,96.48864,79.483-32.90226,80.22235-35.85977S536.42322,374.84628,536.42322,374.84628Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#ffb8b8"
    />
    <circle cx="403.59092" cy="171.87624" r="36.96883" fill="#ffb8b8" />
    <path
      d="M553.42888,348.22873s9.61189,12.5694,6.65439,14.04815-19.96317,2.21813-30.31444,14.78753,12.5694,44.36259,12.5694,44.36259l87.24643-58.41074-35.49007-11.83s-24.39943-13.30878-23.66005-27.35693Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#ffb8b8"
    />
    <path
      d="M671.19262,750.26507h-98.51c-5.58-40.8-8.16-78.19-8.16-78.19s-1.44,37.61-5.27,78.19h-126.56c17.94-120.16,102.99-250.46,102.99-250.46s.74-17.75,2.96-20.71c2.22-2.95-12.57-3.68994-19.23-25.87-4.06-13.54-.41-22.4,3.06-27.24a19.57194,19.57194,0,0,1,4.34-4.56s1.48-15.52,0-17.74c-.19-.29,1.26995-1.31,3.97-2.86,2.25-1.3,5.36-2.97,9.08-4.89,21.31-11.05,62.56-30.6,76.69-37.26,3.1-1.47,4.9-2.31,4.9-2.31l25.14,8.87c19.96,6.65,19.96,48.8,19.96,48.8l-1.13,1.72-30.17,45.7-17.5,26.51995c4.44,2.95-4.43,21.44-4.43,21.44s12.63995,18.16,22.92,45.88a208.66964,208.66964,0,0,1,11.81,47.85,163.68689,163.68689,0,0,1,.76,31.96C646.07263,670.725,658.25262,714.01507,671.19262,750.26507Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#2f2e41"
    />
    <path
      d="M646.94675,369.59286a6.45876,6.45876,0,0,0-9.24709,3.45812c-2.22637,6.12192-4.48282,18.88405-3.67874,45.41851,1.47875,48.79885,2.21813,82.0708,2.21813,82.0708v73.93765s-32.53257,70.98014-14.78753,70.98014S651.766,589.26547,651.766,589.26547s16.26629-85.0283,11.09065-99.81583C662.8566,489.44964,677.45046,387.29262,646.94675,369.59286Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#ffb8b8"
    />
    <ellipse
      cx="399.25175"
      cy="139.07267"
      rx="31.72892"
      ry="21.63336"
      fill="#2f2e41"
    />
    <circle cx="449.63825" cy="91.81685" r="30.2867" fill="#2f2e41" />
    <path
      d="M613.7158,263.18513A30.28313,30.28313,0,0,1,583.542,235.42232c-.06883.833-.11285,1.67312-.11285,2.52389a30.2867,30.2867,0,0,0,60.5734,0c0-.85077-.044-1.69085-.11285-2.52389A30.28313,30.28313,0,0,1,613.7158,263.18513Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#2f2e41"
    />
    <ellipse
      cx="580.78581"
      cy="309.41146"
      rx="2.58782"
      ry="5.91501"
      transform="translate(-198.43547 -6.34316) rotate(-13.43351)"
      fill="#ffb8b8"
    />
    <circle cx="439.45068" cy="163.37341" r="1.47875" fill="#f2f2f2" />
    <path
      d="M230.3626,439.76507a12,12,0,1,1,12-12A12.01344,12.01344,0,0,1,230.3626,439.76507Zm0-22a10,10,0,1,0,10,10A10.01146,10.01146,0,0,0,230.3626,417.76507Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#3f3d56"
    />
    <path
      d="M749.8626,352.26507h-26v-26h26Zm-24-2h22v-22h-22Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#3f3d56"
    />
    <path
      d="M973.91711,492.02944c-46.27673.2133-83.94416.897-83.94416.897s44.77365-59.30728,83.77624-37.33435,83.80662,36.562,83.80662,36.562S1020.19384,491.81615,973.91711,492.02944Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#f2f2f2"
    />
    <path
      d="M831.91711,292.02944c-46.27673.2133-83.94416.897-83.94416.897s44.77365-59.30728,83.77624-37.33435,83.80662,36.562,83.80662,36.562S878.19384,291.81615,831.91711,292.02944Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#f2f2f2"
    />
    <path
      d="M393.27106,317.63362,286.26452,262.60953a14.87767,14.87767,0,0,1-6.42742-20.03442L305.04642,193.55a5.894,5.894,0,0,1,3.59177-2.96308l84.34159-24.59228a13.72272,13.72272,0,0,1,16.79135,8.63432l27.71114,79.04736a15.40617,15.40617,0,0,1-.83775,12.14194l-23.339,45.38793A14.87766,14.87766,0,0,1,393.27106,317.63362Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#f2f2f2"
    />
    <path
      d="M397.52235,298.20365l-108.045-46.12656c-3.48134-1.79015-.217.96149,1.57314-2.51985l13.582-55.20227,132.35614,68.05917-33.46117,41.374C401.73732,307.26947,401.00369,299.9938,397.52235,298.20365Z"
      transform="translate(-142.44419 -149.73493)"
      opacity="0.1"
    />
    <path
      d="M306.13776,191.0858"
      transform="translate(-142.44419 -149.73493)"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <rect
      x="312.2396"
      y="180.3475"
      width="110.68407"
      height="110.68407"
      rx="8.18596"
      transform="translate(-152.55841 305.07022) rotate(-62.78721)"
      className={styles.primary}
    />
    <polygon
      points="276.863 106.382 249.046 160.479 150.613 109.863 178.43 55.767 215.415 104.863 276.863 106.382"
      opacity="0.1"
    />
    <path
      d="M356.2861,257.6562l-47.38889-62.90731a2.87711,2.87711,0,0,0-4.85668.41544l-26.96628,52.44191a8.98362,8.98362,0,0,0,3.88109,12.09745l117.48983,60.41473a8.98362,8.98362,0,0,0,12.09745-3.88109L437.047,264.69379a3.51084,3.51084,0,0,0-3.03543-5.11527Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#f2f2f2"
    />
    <rect
      x="344.79284"
      y="181.39853"
      width="30.95402"
      height="4.37734"
      transform="translate(-18.6137 -294.16458) rotate(27.21279)"
      fill="#f2f2f2"
    />
    <rect
      x="332.331"
      y="208.48021"
      width="91.92406"
      height="2.50133"
      transform="translate(-4.66338 -299.51278) rotate(27.21279)"
      fill="#f2f2f2"
    />
    <rect
      x="328.4705"
      y="215.98781"
      width="91.92406"
      height="2.50133"
      transform="translate(-1.65748 -296.91641) rotate(27.21279)"
      fill="#f2f2f2"
    />
    <rect
      x="324.61"
      y="223.4954"
      width="91.92406"
      height="2.50133"
      transform="translate(1.34841 -294.32003) rotate(27.21279)"
      fill="#f2f2f2"
    />
    <ellipse
      cx="346.90768"
      cy="164.78864"
      rx="12.84266"
      ry="12.81934"
      transform="translate(-100.72506 248.20642) rotate(-62.78721)"
      fill="#fff"
    />
    <path
      d="M353.78145,151.42107a15.32067,15.32067,0,1,0,6.61881,20.631A15.36571,15.36571,0,0,0,353.78145,151.42107Zm-13.53858,19.45105-3.39214-10.57338,2.96571-.95145,2.44386,7.6176,16.07829-5.15822.95145,2.96571-19.04717,6.09974Z"
      transform="translate(-142.44419 -149.73493)"
      fill="#57b894"
    />
  </svg>
);

const NewMessage = memo(_Svg);
export default NewMessage;
