import React, { useContext, useState } from "react";
import AuthLayout from "components/shared/layouts/AuthLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";
import Doctor from "components/shared/ui/illustrations/Doctor";
import { History } from "history";
import { Error } from "./Auth/types";
import { AuthContext } from "components/context/AuthContext";
import { User } from "react-netlify-identity";
import * as ROUTES from "constants/routes";
import { useStoreActions } from "store/state";

const SignUpForm = React.lazy(() => import("components/views/Auth/SignUpForm"));

interface Props {
  history: History;
}
interface State {
  hasError: boolean;
  next: boolean | Function;
  error: Error;
}

const ViewSignUp = ({ history }: Props) => {
  const [state, setState] = useState<State>({
    hasError: false,
    next: false,
    error: null
  });
  const { signupUser } = useContext(AuthContext);
  const setUserEmail = useStoreActions(actions => actions.user.setEmail);

  const onSubmit = async (
    email: string,
    password: string,
    data: Record<string, string>
  ) => {
    signupUser(email, password, data, false)
      .then((user: User) => {
        console.log("Success! Signed up", user);
        setState({ ...state, error: null, hasError: false });
        setUserEmail(email);
        history.push(ROUTES.REGISTRATION_COMPLETE);
      })
      .catch((error: Error) => {
        error && console.log(error.message);
        setState({ ...state, error: error, hasError: true });
      });
  };

  return (
    <AuthLayout banner={Doctor}>
      <Meta title={"Create Account"} />
      <React.Suspense fallback={<Loader absolute />}>
        <SignUpForm
          error={state.error ? state.error.message : null}
          onSubmit={onSubmit}
        />
      </React.Suspense>
    </AuthLayout>
  );
};

export default ViewSignUp;
