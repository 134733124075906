import { useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter, RouteComponentProps } from "react-router";
import { History } from "history";
import { AuthContext } from "components/context/AuthContext";

function sendPageView(location: any) {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
}

interface IGAListener extends RouteComponentProps<any> {
  children?: any;
  trackingId: string;
  history: History;
}

const GAListener = ({ children, trackingId, history }: IGAListener) => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    ReactGA.initialize(trackingId);
    sendPageView(history.location);
    return history.listen(sendPageView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.id) {
      ReactGA.set({ userId: user.id });
    }
  }, [user]);

  return children;
};

interface IAnalyticsEvent {
  category: string;
  action: string;
  value?: number;
  label?: string;
}

export const analyticsEvent = (props: IAnalyticsEvent) => {
  ReactGA.event(props);
};

interface IAnalyticsFilterEvent {
  facet: string;
  filter?: string[];
}

export const logFilterEvent = ({ facet, filter }: IAnalyticsFilterEvent) => {
  if (filter === null || filter === undefined) {
    analyticsEvent({ category: "Removed Filter", action: facet });
  } else {
    analyticsEvent({
      category: "Added Filter",
      action: facet,
      label: filter[0].replace("@", "|")
    });
  }
};

export default withRouter(GAListener);
