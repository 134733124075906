import React from "react";
import AuthLayout from "components/shared/layouts/AuthLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";
import Festivities from "components/shared/ui/illustrations/Festivities";
import Button from "components/shared/ui/button/Button";
import * as ROUTES from "constants/routes";
import { useStoreState } from "store/state";
import { SITE_NAME } from "constants/site";

const ViewRegistrationComplete = () => {
  const email = useStoreState(state => state.user.email);
  return (
    <AuthLayout banner={Festivities}>
      <Meta title={"Account Created!"} />
      <React.Suspense fallback={<Loader absolute />}>
        <div>
          <h1>Thanks for signing up to {SITE_NAME}!!</h1>
          <p>
            We've sent a confirmation email to{" "}
            {email ? (
              <span style={{ fontStyle: "italic" }}>{email}</span>
            ) : (
              "your email address"
            )}
            , please check your inbox to complete the registration process
            before you login.
          </p>
          <Button href={ROUTES.LANDING}>Back to Home</Button>
        </div>
      </React.Suspense>
    </AuthLayout>
  );
};

export default ViewRegistrationComplete;
