import React from "react";
import styles from "./AppLayout.module.scss";
import ErrorBoundary from "components/shared/ErrorBoundary";
import FullPageError from "components/shared/ui/error-view/ErrorView";
import Navbar from "components/shared/ui/navbar/Navbar";
import Footer from "components/shared/ui/footer/Footer";
import * as ROUTES from "constants/routes";

interface Props {
  children: any;
}

export const navItems = [
  { url: ROUTES.HOME, name: "Specialities" },
  { url: ROUTES.COMPLAINTS, name: "Presenting Complaints" },
  { url: ROUTES.DISEASES, name: "Diseases" },
  { url: ROUTES.SIGN_OUT, name: "Sign Out" }
];

const AppLayout = (props: Props) => (
  <div className={styles.root}>
    <Navbar items={navItems} />
    <ErrorBoundary errorView={FullPageError}>
      <div className={styles.content}>{props.children}</div>
    </ErrorBoundary>

    <Footer dark />
  </div>
);

export default AppLayout;
