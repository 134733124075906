import React from "react";
import Container from "components/shared/layouts/Container";
import * as ROUTES from "constants/routes";
import { Link } from "react-router-dom";

const RecoveryInvalid = () => (
  <Container>
    <h1>Invalid Token</h1>
    <p>Sorry, it seems the recovery token was invalid, please try again.</p>
    <Link to={ROUTES.PASSWORD_FORGET}>Back to Password Reset</Link>
  </Container>
);

export default RecoveryInvalid;
