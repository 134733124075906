import React, { useContext, useState } from "react";
import AuthLayout from "components/shared/layouts/AuthLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";
import { AuthContext } from "components/context/AuthContext";
import NewMessage from "components/shared/ui/illustrations/NewMessage";
import { Error } from "./Auth/types";
import { useStoreActions, useStoreState } from "store/state";
import { useHistory } from "react-router";
import * as ROUTES from "constants/routes";
import RecoveryInvalid from "./Auth/RecoveryInvalid";

const PasswordResetForm = React.lazy(() =>
  import("components/views/Auth/PasswordResetForm")
);

const ViewRecovery = () => {
  const history = useHistory();
  const token = useStoreState(state => state.user.recoveryToken);
  const resetToken = useStoreActions(actions => actions.user.resetToken);
  const { recoverAccount, user, updateUser, setUser } = useContext(AuthContext);
  const [error, setError] = useState<Error>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  if (token && !fetching) {
    setFetching(true);
    recoverAccount(true).then((user: any) => {
      setUser(user);
      resetToken();
      setFetching(false);
    });
  }
  const onSubmit = async (password: string) => {
    updateUser({ password })
      .then(() => {
        setError(null);
        history.push(ROUTES.HOME);
      })
      .catch((error: Error) => {
        setError(error);
      });
  };

  return (
    <AuthLayout banner={NewMessage}>
      <Meta title={"Reset Password"} />
      {fetching ? (
        <Loader />
      ) : user ? (
        <React.Suspense fallback={<Loader absolute />}>
          <PasswordResetForm
            error={error ? error.message : undefined}
            email={user && user.email}
            onSubmit={onSubmit}
          />
        </React.Suspense>
      ) : (
        <RecoveryInvalid />
      )}
    </AuthLayout>
  );
};

export default ViewRecovery;
