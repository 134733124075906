import React from "react";
import cx from 'classnames'
import styles from "./Container.module.scss";

interface Props {
  className?: string;
  children: any;
}
const Container = (props: Props) => (
  <div className={cx(props.className,styles.container)}>{props.children}</div>
);

export default Container;
