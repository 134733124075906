import { Action, action } from "easy-peasy";

const initialState = {
  recoveryToken: undefined as string | undefined,
  email: undefined as string | undefined
};

type IState = typeof initialState;
export interface UserModel extends IState {
  setRecoveryToken: Action<UserModel, string | undefined>;
  resetToken: Action<UserModel, undefined>;
  setEmail:Action<UserModel, string | undefined>
}

export const store: UserModel = {
  ...initialState,
  setRecoveryToken: action((state: any, payload) => {
    state.recoveryToken = payload;
  }),
  resetToken: action((state: any) => {
    state.recoveryToken = undefined;
  }),
  setEmail: action((state: any, payload) => {
    state.email = payload;
  }),
};
