import React from "react";
import Splash from "./viewSplash";
import { useHistory } from "react-router-dom";
import { useStoreActions } from "store/state";
import * as ROUTES from "constants/routes";

const checkRecoveryHash = () => {
  const route = /recovery_token=([^&]+)/;
  const hash = (document.location.hash || "").replace(/^#\/?/, "");
  if (!hash) return; // early terminate if no hash

  const params = {} as { [key: string]: string };

  hash.split("&").forEach(pair => {
    const [key, value] = pair.split("=");
    params[key] = value;
  });

  const recoveryRoute = hash.match(route);
  return !!recoveryRoute ? params["recovery_token"] : null;
};

const ViewRoot = () => {
  const history = useHistory();
  const setRecoveryToken = useStoreActions(
    actions => actions.user.setRecoveryToken
  );
  const recoveryToken = checkRecoveryHash();

  if (recoveryToken) {
    setRecoveryToken(recoveryToken);
    history.push(`${ROUTES.RECOVERY}#recovery_token=${recoveryToken}`);
  }
  return <Splash />;
};

export default ViewRoot;
