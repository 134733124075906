import React from "react";
import AppLayout from "components/shared/layouts/AppLayout";
import Loader from "components/shared/ui/loader/Loader";
import Meta from "components/shared/Meta";

const Content = React.lazy(() => import("./ComplaintList/Content"));

const ComplaintList = () => (
  <AppLayout>
    <Meta title={"Complaints List"} />
    <React.Suspense fallback={<Loader absolute />}>
      <Content />
    </React.Suspense>
  </AppLayout>
);

export default ComplaintList;
