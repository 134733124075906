export const verifyRole = (
  user: { app_metadata: { roles: string[] } } | undefined,
  requiredRole?: string | string[],
  excludedRole?: string | string[]
) => {
  const roles = user && user.app_metadata.roles ? user.app_metadata.roles : [];
  return checkRole(roles, requiredRole) && checkRole(roles, excludedRole, true);
};

const checkRole = (
  userRole: string | string[],
  reqRole?: string | string[],
  exclude?: boolean
) => {
  if (!reqRole || reqRole.length === 0) {
    return true;
  }
  const check =
    (typeof reqRole === "string" && userRole.includes(reqRole)) ||
    (Array.isArray(reqRole) && reqRole.some(r => userRole.includes(r)));
  return exclude ? !check : check;
};
