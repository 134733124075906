import { Action, action, Thunk, thunk } from "easy-peasy";
import Crossfilter, {
  updateActiveFilters
} from "store/crossfilter/Crossfilter";
import { defaultFilters, FACETS } from "constants/facets";
import {
  IDataBuckets,
  FacetBuckets,
  FacetExport,
  IDataRow
} from "./crossfilter/CrossfilterTypes";

import { logFilterEvent } from "components/context/GoogleAnalytics";
import { fetchDiseases } from "api/fetch";
import { IPresentingCcmplaint } from "store/presentingComplaintState";

let xf = new Crossfilter();

export interface CrossfilterModel {
  fetched: boolean;
  current: string | undefined;
  allData: IDataRow[];
  facets: { [key: string]: FacetBuckets };
  data: IDataBuckets | undefined;
  // filters: {[key:string]:string[]};
  filters: Record<string, string[]>;

  complaintUpdated: Action<CrossfilterModel, IPresentingCcmplaint>;
  facetsUpdated: Action<CrossfilterModel, FacetExport>;
  filtersUpdated: Action<CrossfilterModel, { [key: string]: string[] }>;
  resetFilters: Action<CrossfilterModel, { [key: string]: string[] }>;

  resetFacets: Thunk<CrossfilterModel, IPresentingCcmplaint>;
  updateFacets: Thunk<CrossfilterModel, void>;
  updateFilter: Thunk<
    CrossfilterModel,
    { bucket: any; key: string; type?: string }
  >;
  initCrossfilter: Action<CrossfilterModel, IDataRow[]>;
  fetchDiseaseData: Thunk<CrossfilterModel, void>;
}

const xfStore: CrossfilterModel = {
  fetched: false,
  current: undefined,
  allData: [],
  facets: {},
  data: [],
  filters: {},

  complaintUpdated: action((state, payload) => {
    state.current = payload.slug;
  }),

  facetsUpdated: action((state, payload) => {
    state.data = payload.data;
    let facets = { ...payload };
    delete facets.data;
    state.facets = facets;
  }),

  filtersUpdated: action((state, payload) => {
    state.filters = { ...state.filters, ...payload };
  }),

  resetFilters: action((state, payload) => {
    state.filters = payload;
  }),

  resetFacets: thunk(async (actions, payload) => {
    const pc = payload; //PRESENTING_COMPLAINTS_MAP[payload] || errorComplaint;
    actions.complaintUpdated(pc);

    //Set default filters
    let defaults: Record<string, string[]> = {
      ...defaultFilters,
      ...pc.filter_defaults,
      pc: [`${pc.id}`]
    };
    await xf.clearAllFilters();
    for (let key in defaults) {
      xf.applyFilter(key, defaults[key]);
    }
    actions.resetFilters(defaults);
    actions.updateFacets();
  }),
  updateFacets: thunk(async actions => {
    let facets = await xf.reduceToObject();
    actions.facetsUpdated(facets);
  }),
  updateFilter: thunk(async (actions, payload, { getState }) => {
    let currentFilters = getState().filters;
    let { key, bucket, type } = payload;

    logFilterEvent({ facet: key, filter: bucket });

    let newFilters = await updateActiveFilters(
      currentFilters[key],
      bucket,
      type
    );
    actions.filtersUpdated({ [key]: newFilters });

    xf.applyFilter(key, newFilters);
    actions.updateFacets();
  }),
  initCrossfilter: action((state, payload) => {
    const data = payload;
    xf = new Crossfilter();
    xf.init(data, FACETS);
    state.fetched = true;
  }),
  fetchDiseaseData: thunk(async actions => {
    let res = await fetchDiseases();
    actions.initCrossfilter(res);
  })
};

export const store = {
  ...xfStore
};
