import React from "react";
import AppLayout from "components/shared/layouts/AppLayout";
import Meta from "components/shared/Meta";
import LandingBackgroud from "components/shared/layouts/LandingBackground";
import Container from "components/shared/layouts/Container";
import styles from "components/views/SpecialisationList/SpecialisationList.module.scss";
import { verifyRole } from "utils/verifyRole";
import { FORBIDDEN_ROLES } from "constants/roles";
import CTA from "components/shared/ui/call-to-action/CallToAction";
import { Link } from "react-router-dom";
import * as ROUTES from "constants/routes";

interface Props {
  user: any;
}

const Forbidden = ({ user }: Props) => {
  const blocked = verifyRole(user, FORBIDDEN_ROLES);
  return (
    <AppLayout>
      <LandingBackgroud />
      <Container className={styles.main}>
        <div className={styles.cardWrapper}>
          {blocked ? <Blocked /> : <NotRegistered />}
        </div>
      </Container>
    </AppLayout>
  );
};

const NotRegistered = () => (
  <>
    <Meta title={"Pending Registration"} />
    <h1>Pending Registration</h1>
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h2>
          Thank you for registering your details on the Diagnostic Dashboard and
          being willing to participate in the research.
        </h2>{" "}
        <br />
        As we're currently in beta phase, we are working with the University of
        Auckland to conduct research on the effectiveness of the platform.
        <br />
        <br />
        We will shortly send you an email on whether you have been randomised to
        the 'case' or the 'control' group for the first half of 2021. If you're
        in the case group, you can use the same email address and password you
        entered initially to login and will have access to all parts of the website. At
        the start of each attachment those in the control group will receive
        suggested topics and references to help with learning, be sent regular emails 
        on clinical reasoning and improving diagnosis, and have full access to the site 
        from midway through the year.
        <br />
        <br />
        If you have already recieved an email from us allowing you access, and
        can still see this page, you may need to{" "}
        <b>
          <Link to={ROUTES.SIGN_OUT}>sign out</Link>
        </b>{" "}
        first to refresh your permissions.
      </div>
      <CTA
        title={""}
        subtitle={
          "If you have any questions, please don't hesitate to contact us"
        }
        externalHref={`mailto:wrightmedicalnz@gmail.com?subject=Registration`}
        button={"Email Matt (wrightmedicalnz@gmail.com)"}
        noBg
      />
    </div>
  </>
);

const Blocked = () => (
  <>
    <Meta title={"No Access"} />
    <h1>Access Forbidden</h1>
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h2 className={styles.danger}>
          Unfortunately, you have been randomised to the control group of the
          research study and cannot access the Diagnostic Dashboard at this
          stage.
        </h2>{" "}
        <br />
        We hope that you find the suggested topics, references and helpful with
        the attachment. 
        <br />
        <br />
        It is vitally important for this website to be available in the future 
        that you continue to feedback after each attachment.
        <br />
        <br /> 
        A reminder you will have full access to the site from midway through 2021.
      </div>
      <CTA
        title={""}
        subtitle={
          "If you have any questions, please don't hesitate to contact us"
        }
        externalHref={`mailto:wrightmedicalnz@gmail.com?subject=Blocked`}
        button={"Email Matt (wrightmedicalnz@gmail.com)"}
        noBg
      />
    </div>
  </>
);

export default Forbidden;
