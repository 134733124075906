import React from "react";
import AppLayout from "components/shared/layouts/AppLayout";
import Loader from "components/shared/ui/loader/Loader";
import { specialities } from "constants/Specialities";
import { History } from "history";
import * as ROUTES from "constants/routes";

const Content = React.lazy(() => import("./DiseaseList/Content"));

interface Props {
  match: { params: { slug: string } };
  history: History;
}

const DiseaseList = (props: Props) => {
  const {
    match: { params }
  } = props;
  const slug = params.slug;
  const spec = specialities.find(s => s.slug === slug);
  const filters = spec && spec.pc;
  const title = spec && spec.short_name;

  return (
    <AppLayout>
      <React.Suspense fallback={<Loader absolute />}>
        <Content
          title={title}
          filters={filters}
          history={props.history}
          parentRoute={ROUTES.DISEASES}
          childRoute={ROUTES.DISEASE_DETAIL}
        />
      </React.Suspense>
    </AppLayout>
  );
};

export default DiseaseList;
