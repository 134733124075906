import React from "react";
import AppLayout from "components/shared/layouts/AppLayout";
import Loader from "components/shared/ui/loader/Loader";
import { specialities } from "constants/Specialities";
import { History } from "history";
import Meta from "components/shared/Meta";

const Content = React.lazy(() => import("./SpecialityDetailList/Content"));

interface Props {
  match: { params: { slug: string } };
  history: History;
}

const SpecialityDetailList = (props: Props) => {
  const {
    match: { params }
  } = props;
  const slug = params.slug;
  const spec = specialities.find(s => s.slug === slug);
  const pcFilters = spec && spec.pc;
  const diseaseFilters = spec && spec.dis;
  const title = spec && spec.long_name;

  return (
    <AppLayout>
      <Meta title={title} />
      <React.Suspense fallback={<Loader absolute />}>
        {spec && (
          <Content
            slug={slug}
            spec={spec && spec}
            complaints={pcFilters}
            diseases={diseaseFilters}
            history={props.history}
          />
        )}
      </React.Suspense>
    </AppLayout>
  );
};

export default SpecialityDetailList;
