import { Action, action, Thunk, thunk } from "easy-peasy";
import { fetchtooltip } from "api/fetch";

export interface ITooltip {
  id: number;
  disease: string;
  definition: string;
  symptom_blurb: string;
  symptoms: string;
  signs_blurb: string;
  signs: string;
  management_blurb: string;
  management: string;
  investigation_blurb: string;
  investigation: string;
  referral_simple: string;
  referral_complicated: string;
  specialty: string;
  patient_ref: string;
  mps_ref: string;
  pathway: string;
  national_ref: string;
  ortho_ref: string;
  epi: string;
  complication_reason: string;
  def_blurb: string;
  specialty_if_complicated: string;
  university_reference: string;
}

type ITooltips = ITooltip[];

export interface TooltipModel {
  current: ITooltip | undefined;
  current_id: number | undefined;
  fetching: boolean;
  fetched: boolean;
  byId: {
    [key: string]: ITooltip;
  };
  allIds: number[];
  tooltipSet: Action<TooltipModel, number>;
  loadTooltips: Action<TooltipModel, ITooltips>;
  fetchingTooltip: Action<TooltipModel, boolean>;
  setTooltip: Thunk<TooltipModel, number>;
  fetchTooltip: Thunk<TooltipModel>;
}

const initialState = {
  current: undefined,
  current_id: undefined,
  fetched: false,
  fetching: false,
  byId: {},
  allIds: []
};

export const store: TooltipModel = {
  ...initialState,

  tooltipSet: action((state: any, payload) => {
    state.current = state.byId[payload];
    state.current_id = payload;
  }),
  loadTooltips: action((state: any, payload) => {
    state.byId = payload.reduce(
      function(dict, t) {
        dict[t.id] = t;
        return dict;
      },
      {} as {
        [key: number]: ITooltip;
      }
    );
    state.allIds = payload.map(t => t.id);
    state.fetched = true;
  }),
  fetchingTooltip: action((state: any, payload) => {
    state.fetching = payload;
  }),
  setTooltip: thunk(async (actions, payload, { getState }) => {
    if (!getState().fetched) {
      await actions.fetchTooltip();
    }
    if (getState().current_id !== payload) {
      actions.tooltipSet(payload);
    }
  }),
  fetchTooltip: thunk(async actions => {
    actions.fetchingTooltip(true);
    let res = await fetchtooltip();
    actions.loadTooltips(res);
    actions.fetchingTooltip(false);
  })
};
