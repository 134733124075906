import React, { createContext, ReactNode } from "react";
import { useNetlifyIdentity } from "react-netlify-identity";
import { SITE_URL } from "constants/site";

const AuthContext = createContext<any | null>({});

function AuthContextProvider({ children }: { children: ReactNode }) {
  const identity = useNetlifyIdentity(SITE_URL);

  return (
    <AuthContext.Provider value={identity}>{children}</AuthContext.Provider>
  );
}

export { AuthContextProvider, AuthContext };
