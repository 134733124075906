import React from "react";
import styles from "./Loader.module.scss";
interface Props {
  absolute?: boolean;
}

const Loader = ({ absolute }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.indicator}>
        <svg width="16px" height="12px" viewBox="0 0 16 12">
          <polyline
            className={styles.back}
            points="1 6 4 6 6 11 10 1 12 6 15 6"
          />
          <polyline
            className={styles.front}
            points="1 6 4 6 6 11 10 1 12 6 15 6"
          />
        </svg>
      </div>
    </div>
  );
};

export default Loader;
