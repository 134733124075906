import React from "react";
import Meta from "components/shared/Meta";
import Container from "components/shared/layouts/Container";
import NotFound from "components/shared/ui/illustrations/NotFound";
import AppLayout from "components/shared/layouts/AppLayout";

interface Props {}
interface State {}
class ViewNotFound extends React.PureComponent<Props, State> {
  public render() {
    return (
      <AppLayout>
        <Meta title={"Page Not Found"} />
        <Container>
          <h1>Page not Found!</h1>
          <p>
            Sorry, it seems you're trying to access a page that doesn't exist.
            Check the spelling of the URL you were trying to access and try
            again
          </p>
          <div>
            <NotFound alternative />
          </div>
        </Container>
      </AppLayout>
    );
  }
}

export default ViewNotFound;
