import React, { useContext } from "react";
import { Route, Redirect, RouteComponentProps, RouteProps } from "react-router";
import * as ROUTES from "constants/routes";
import { AuthContext } from "components/context/AuthContext";
import { verifyRole } from "utils/verifyRole";
import Forbidden from "components/views/viewForbidden";

interface Props extends RouteProps {
  roles?: string | string[];
  excludedRoles?: string | string[];
}

const PrivateRoute = ({
  roles,
  excludedRoles,
  component: Component,
  ...rest
}: Props) => {
  const identity = useContext(AuthContext);
  const isLoggedIn = identity.user;
  return (
    <Route
      render={(props: RouteComponentProps<any>) => {
        if (isLoggedIn && Component) {
          return verifyRole(identity.user, roles, excludedRoles) ? (
            <Component {...props} />
          ) : (
            <Forbidden user={identity.user} />
          );
        }
        return (
          <Redirect
            to={{ pathname: ROUTES.SIGN_IN, state: { from: props.location } }}
          />
        );
      }}
      {...rest}
    />
  );
};

export default PrivateRoute;
